import { Routes, Route } from "react-router-dom";
import Header from "../../Components/Dashboard/Header";
import Sidebar from "../../Components/Dashboard/Sidebar";
import DashboardPage from "../../Components/Dashboard/DashboardPage";
import WalletPage from "../../Components/Dashboard/WalletPage";
import LoansPage from "../../Components/Dashboard/LoansPage";
import TradePage from "../../Components/Dashboard/TradePage";
import AccountsPage from "../../Components/Dashboard/AccountsPage";
import SettingsPage from "../../Components/Dashboard/SettingsPage";
import ProfilePage from "../../Components/Dashboard/ProfilePage";
import DevelopersPage from "../../Components/Dashboard/DevelopersPage";

const Dashboard = () => {
  // Function to render the active component based on the active item name
  return (
    <div className="bg-atlas_dark w-full h-full min-h-screen">
      <Header />
      <div className="">
        <Sidebar>
          <div className="relative pb-10">
            {/* Offsets the main content */}
            <Routes>
              <Route path="/" element={<DashboardPage />} />
              <Route path="/wallet" element={<WalletPage />} />
              <Route path="/loans" element={<LoansPage />} />
              <Route path="/trade" element={<TradePage />} />
              <Route path="/accounts" element={<AccountsPage />} />
              <Route path="/settings/*" element={<SettingsPage />} />
              <Route path="/profile" element={<ProfilePage />} />
              <Route path="/developers/*" element={<DevelopersPage />} />
            </Routes>
            {process.env.REACT_APP_ENV !== "prod" ? (
              <div className="">
                <div className="fixed left-1/2 -translate-x-1/2 inline-flex items-center space-x-3 p-2 font-thin text-white text-lg text-nowrap bottom-5 scale-50 lg:scale-100 rounded-lg bg-atlas_orange mx-auto">
                  <p>You are now in Sandbox Mode. All transactions and credentials are for testing purposes only.</p>
                  <a href="https://atlaxchange.com/dashboard" className="btn text-sm" target="_blank" rel="noreferrer">
                    Go to Live mode
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </Sidebar>
      </div>
    </div>
  );
};

export default Dashboard;
