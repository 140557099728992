export default function () {
	return (
		<svg width="116" height="116" viewBox="0 0 116 116" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M102.714 57.9993C102.714 33.3076 82.6969 13.291 58.0052 13.291C33.3135 13.291 13.2969 33.3076 13.2969 57.9993C13.2969 82.6911 33.3135 102.708 58.0052 102.708C82.6969 102.708 102.714 82.6911 102.714 57.9993Z"
				fill="#FF8205"
			/>
			<path
				d="M43.5 58.0007L53.1667 67.6673L72.5 48.334"
				stroke="white"
				strokeWidth="7.25"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
