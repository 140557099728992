export default () => {
    return (
      <svg
        width="12"
        height="7"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.861278 1.10005C1.12163 0.839698 1.54374 0.839698 1.80409 1.10005L5.99935 5.29531L10.1946 1.10005C10.455 0.839698 10.8771 0.839698 11.1374 1.10005C11.3978 1.3604 11.3978 1.78251 11.1374 2.04286L6.47075 6.70952C6.34573 6.83455 6.17616 6.90478 5.99935 6.90478C5.82254 6.90478 5.65297 6.83455 5.52794 6.70952L0.861278 2.04286C0.600928 1.78251 0.600928 1.3604 0.861278 1.10005Z"
          fill="white"
        />
      </svg>
    );
  };