import { useContext, useState, useEffect } from "react";
import myContext from "../../Context/AppContext";
import { Link, useLocation } from "react-router-dom";
import settingsicon from "../../assets/settingsicon";
import briefcase from "../../assets/briefcase";
import dashboardIcon from "../../assets/dashboardIcon";

const SettingsMenu = () => {
	const MenuListTop = [
		{ name: "General", icon: briefcase, route: "/dashboard/settings/general" },
		{ name: "Password", icon: briefcase, route: "/dashboard/settings/password" },
		{ name: "PIN", icon: briefcase, route: "/dashboard/settings/pin" },
		{ name: "Preferences", icon: briefcase, route: "/dashboard/settings/preferences" },
		{ name: "Notifications", icon: briefcase, route: "/dashboard/settings/notifications" },
		{ name: "Users & Roles", icon: dashboardIcon, route: "/dashboard/settings/users" },
		// { name: "trade", icon: GroupIcon, route: "/dashboard/trade" },
	];

	const [activeNavItem, setActiveNavItem] = useState("Users");

	const { sidebarOpen, updateSidebarOpen, topComponentRef } = useContext(myContext);
	const [sidebarHeight, setSidebarHeight] = useState("100vh");

	const handleItemClick = (itemName: any) => {
		setActiveNavItem(itemName);
		updateSidebarOpen(false);
	};

	const location = useLocation();

	useEffect(() => {
		const adjustSidebarHeight = () => {
			const topComponentHeight = topComponentRef.current?.offsetHeight || 0;
			setSidebarHeight(`calc(100vh - ${topComponentHeight}px)`);
		};

		// Set activeNavItem based on the current route
		const currentPath = location.pathname.split("/")[2]; // Assuming the structure is /dashboard/[name]
		if (currentPath && MenuListTop.concat(MenuListTop).some((item) => item.route.endsWith(currentPath))) {
			setActiveNavItem(currentPath);
		} else {
			setActiveNavItem("dashboard"); // Fallback to dashboard
		}

		// Adjust sidebar height on mount and window resize
		adjustSidebarHeight();
		window.addEventListener("resize", adjustSidebarHeight);

		return () => window.removeEventListener("resize", adjustSidebarHeight);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, topComponentRef]);

	return (
		<div className="flex">
			{/* Overlay */}
			{sidebarOpen && (
				<div className="fixed bg-black bg-opacity-50 z-10 lg:hidden" onClick={() => updateSidebarOpen(false)}></div>
			)}

			{/* Sidebar */}
			<div
				className={`fixed border-r border-atlas_gray left-0 transform ${
					sidebarOpen ? "translate-x-0" : "-translate-x-full"
				} w-10/12 md:w-3/12 bg-white dark:bg-atlas_dark text-white transition-transform duration-300 ease-in-out z-20 lg:translate-x-0 lg:inset-auto lg:w-60`}
			>
				{/* Sidebar content */}

				<nav className="flex flex-col justify-between pb-10 lg:pb-5" style={{ height: sidebarHeight }}>
					{/* Your nav items here */}
					<ul className="capitalize border-b border-atlas_gray ">
						{MenuListTop.map((item, index) => (
							<Link key={index} to={item.route}>
								<li
									key={index}
									className={`flex items-center my-5 md:my-2 ml-5 pl-4 py-2 cursor-pointer rounded-l-xl hover:bg-gray-300 hover:text-gray-100 hover:bg-opacity-10 ${
										activeNavItem === item.name
											? "bg-white text-black rounded-l-xl transition-transform duration-700 ease-in-out"
											: "bg-transparent text-white"
									}`}
									onClick={() => handleItemClick(item.name)}
								>
									<div className="w-8 h-8 rounded-full flex items-center justify-center mr-2">
										<item.icon />{" "}
									</div>
									{item.name}
								</li>
							</Link>
						))}
					</ul>
				</nav>
			</div>

			{/* Page content */}
		</div>
	);
};

export default SettingsMenu;
