import { toast } from "react-toastify";
import CopyIcon  from "../assets/Copy";

interface Props {
  text: string;
}

export default function Copy({text}: Props) {
  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success("Text copied to clipboard");
      })
      .catch((err) => toast.error("Failed to copy text to clipboard"));
  };

  return (
    <span
      onClick={() => handleCopy(text)}
      className="text-blue-500 hover:text-blue-700 cursor-pointer"
      aria-label={`Copy ${text}`}
    >
      <CopyIcon />
    </span>
  );
}
