import Spinner from "../assets/spinner";

interface prop {
  label: string;
  onFileSelect?: (file: File | null) => void;
  id: string;
  disable?: boolean;
  onClick?: () => void;
  className?: string;
  loader?: boolean;
}

export function Button({ label, onClick, disable, className, loader }: prop) {
  return (
    <button
      type="submit"
      disabled={disable}
      onClick={onClick}
      className={`btn hover:brightness-90 bg-atlas_orange mb-10 flex justify-center items-center ${className}`}
    >
      {loader ? (
        <div>
          <Spinner />
        </div>
      ) : null}
      {label}
    </button>
  );
}

export function UploadButton({ label, onFileSelect, id }: prop) {
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0] ? event.target.files[0] : null;
    // Call the callback with the selected file
    if (onFileSelect) {
      onFileSelect(file);
    }
  };

  return (
    <div className="mb-10">
      <button
        type="button"
        className="bg-transparent py-2 w-full rounded-md text-atlas_orange text-sm font-semibold text-center cursor-pointer font-manrope border-2 border-solid border-atlas_orange"
        onClick={() => document.getElementById(id)?.click()}
      >
        {label}
      </button>

      <input
        type="file"
        accept="image/jpg, application/pdf , image/jpeg, image/png"
        data-max-size="1048"
        id={id}
        className="hidden"
        name="upload"
        onChange={handleFileChange}
      />

      <p className="text-sm text-atlas_darkgrey pt-3">JPG, PDF, JPEG, PNG are accepted. Max file size 5MB</p>
    </div>
  );
}

export default Button;
