import React, { useState, useRef, useEffect } from "react";

interface PinProps {
  length?: number; // Optional: Number of inputs, default is 6
  onChange?: (pin: string) => void; // Optional: Callback when all inputs are filled
  className?: string; // Optional: Additional CSS classes for styling
}

function PinComponent({ length = 4, onChange, className = "" }: PinProps) {
  const inputRefs = useRef<React.RefObject<HTMLInputElement>[]>(
    Array.from({ length }, () => React.createRef<HTMLInputElement>())
  );
  const [pins, setPins] = useState<string[]>(Array(length).fill(""));

  useEffect(() => {
    // Focus the first input on component mount
    inputRefs.current[0]?.current?.focus();
  }, [length]);

  useEffect(() => {
    // Check if all inputs are filled
    const fullCode = pins.join("");
    if (onChange) { onChange(fullCode); }
  }, [pins, length, onChange]);

  const handleChange = (value: string, index: number) => {
    const newPins = [...pins];
    newPins[index] = value.slice(0, 1);
    setPins(newPins);

    if (value) {
      focusNextInput(index);
    }
  };

  const focusNextInput = (currentIndex: number) => {
    if (currentIndex < length - 1) {
      inputRefs.current[currentIndex + 1]?.current?.focus();
    }
  };

  const focusPrevInput = (currentIndex: number) => {
    if (currentIndex > 0) {
      inputRefs.current[currentIndex - 1]?.current?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !pins[index] && index > 0) {
      focusPrevInput(index);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const pasteDataArray = pasteData.split("");
    setPins(
      pasteDataArray.concat(Array(length - pasteDataArray.length).fill(""))
    );

    const nextIndexToFocus =
      pasteDataArray.length < length ? pasteDataArray.length : length - 1;
    inputRefs.current[nextIndexToFocus]?.current?.focus();
  };

  return (
    <div className={`flex space-x-3 ${className}`}>
      {pins.map((pin, index) => (
        <input
          key={index}
          ref={inputRefs.current[index]}
          type="number"
          inputMode="numeric"
          placeholder="-"
          className={`md:w-12 md:h-12 lg:w-16 lg:h-16 py-3 md:py-5 text-center text-atlas_orange bg-transparent dark:bg-atlas_gray border-2 rounded-md focus:ring-0 focus:outline-none focus:border-atlas_orange ${pin ? "border-atlas_orange" : "border-atlas_slategrey"}`}
          maxLength={1}
          value={pin}
          onChange={(e) => handleChange(e.target.value, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  )
}

export default PinComponent;