import React, { useState, useEffect, useRef } from "react";
import countryList from "react-select-country-list";

interface Country {
  label: string;
  value: string;
}

interface CustomSelectProps {
  onSelect: (value: string) => void;
  text?: string;
}

const CustomSelect: React.FC<CustomSelectProps> = ({ onSelect, text }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] =
    useState<string>("Choose a country");
  const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const options: Country[] = countryList().getData();
  console.log(options);

  const dropdownRef = useRef<HTMLDivElement | null>(null);


  useEffect(() => {
    if (searchTerm) {
      const matchIndex = options.findIndex((country) =>
        country.label.toLowerCase().startsWith(searchTerm.toLowerCase())
      );

      if (matchIndex !== -1) {
        setHighlightedIndex(matchIndex);
        if (dropdownRef.current) {
          const optionElement = dropdownRef.current.querySelector(
            `[data-value="${options[matchIndex].value}"]`
          );
          if (optionElement) {
            (optionElement as HTMLElement).scrollIntoView({ block: "nearest" });
          }
        }
      }
    }

    const timeout = setTimeout(() => setSearchTerm(""), 500);
    return () => clearTimeout(timeout);
  }, [searchTerm, options]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const handleSelect = (country: Country) => {
    setSelectedCountry(country.label);
    setIsOpen(false);
    setHighlightedIndex(-1);
    onSelect(country.label);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (!isOpen) {
      setIsOpen(true);
      return;
    }

    if (event.key.length === 1) {
      setSearchTerm((prev) => prev + event.key);
    } else if (event.key === "ArrowDown") {
      setHighlightedIndex((prev) => (prev < options.length - 1 ? prev + 1 : 0));
    } else if (event.key === "ArrowUp") {
      setHighlightedIndex((prev) => (prev > 0 ? prev - 1 : options.length - 1));
    } else if (event.key === "Enter" && highlightedIndex !== -1) {
      handleSelect(options[highlightedIndex]);
    }
  };

  return (
    <div
      tabIndex={0}
      onKeyDown={handleKeyDown}
      className="w-full text-black dark:text-white focus:outline-none"
      ref={dropdownRef}
    >
      <div
        onClick={() => setIsOpen(!isOpen)}
        className="block w-full border bg-atlas_silver dark:bg-atlas_darker px-2 py-2  rounded-md cursor-pointer border-atlas_slate dark:border-atlas_darkgray"
      >
        {selectedCountry}
        <span className="absolute left-0 lg:-bottom-6 -bottom-10 text-atlas_slategrey text-sm">{text}</span>
      </div>
      

      {isOpen && (
        <div
          className="mt-1 w-full bg-atlas_grey dark:bg-atlas_gray 
                     border border-atlas_slate dark:border-atlas_darkgray 
                     rounded-md max-h-52 overflow-y-auto scrollbar-hide"
        >
          {options.map((country, index) => (
            <div
              key={country.value}
              data-value={country.value}
              onClick={() => handleSelect(country)}
              className={`px-4 py-2 cursor-pointer ${
                highlightedIndex === index
                  ? "bg-atlas_grey dark:bg-atlas_gray"
                  : "hover:bg-gray-100 dark:hover:bg-gray-700"
              }`}
            >
              {country.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
