interface Props {
	strokeColor?: string;
}
export default function ({ strokeColor }: Props) {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.60386 4.16905C8.95919 2.70537 11.0408 2.70537 11.3961 4.16905C11.6257 5.11456 12.709 5.56327 13.5398 5.057C14.8261 4.27328 16.298 5.74521 15.5143 7.03144C15.008 7.86233 15.4567 8.9456 16.4022 9.17514C17.8659 9.53048 17.8659 11.6121 16.4022 11.9674C15.4567 12.197 15.008 13.2802 15.5143 14.1111C16.298 15.3974 14.8261 16.8693 13.5398 16.0856C12.709 15.5793 11.6257 16.028 11.3961 16.9735C11.0408 18.4372 8.95919 18.4372 8.60386 16.9735C8.37431 16.028 7.29105 15.5793 6.46016 16.0856C5.17392 16.8693 3.70199 15.3974 4.48571 14.1111C4.99198 13.2802 4.54327 12.197 3.59776 11.9674C2.13408 11.6121 2.13408 9.53048 3.59776 9.17514C4.54327 8.9456 4.99198 7.86233 4.48571 7.03144C3.70199 5.74521 5.17392 4.27328 6.46015 5.057C7.29105 5.56327 8.37431 5.11456 8.60386 4.16905Z"
				stroke={strokeColor}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.5 10.5713C12.5 11.952 11.3807 13.0713 10 13.0713C8.61929 13.0713 7.5 11.952 7.5 10.5713C7.5 9.19058 8.61929 8.07129 10 8.07129C11.3807 8.07129 12.5 9.19058 12.5 10.5713Z"
				stroke={strokeColor}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
