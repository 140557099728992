import Button from "../button";
import { useEffect, useState } from "react";
import { useAppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import Copy from "../copier";

interface IWebhook {
  signature: string;
  url: string;
}

function WebhookForm() {
  const { sendRequest } = useAppContext();
  const [webhooks, setWebhooks] = useState<IWebhook[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  async function getWebhooks() {
    try {
      let response = await sendRequest("GET", "/webhooks", null);
      setWebhooks(response.data.data);
    } catch (e: any) {
      if (e.response) {
        e.response.data.message.split(";").map((m: any) => {
          toast.error(m);
        });
      }
    } finally {
    }
  }

  async function createWebhooks(url: string) {
    try {
      setLoading(true);
      let response = await sendRequest("POST", "/webhooks", { url });
      toast.success(response.data.message);
      setWebhooks([response.data.data]);
    } catch (e: any) {
      if (e.response) {
        e.response.data.message.split(";").map((m: any) => {
          toast.error(m);
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const { handleSubmit, getFieldProps, errors, touched } = useFormik({
    initialValues: {
      url: "",
    },
    validationSchema: Yup.object({
      url: Yup.string().url("Webhook must be a url").required("Webhook URL is required"),
    }),
    onSubmit: (values) => {
      createWebhooks(values.url);
    },
  });

  useEffect(() => {
    getWebhooks();
  }, []);

  return (
    <div className="w-full">
      <div className="flex flex-col">
        <section className="flex flex-col my-10 space-y-5 w-1/2">
          {webhooks.length ? (
            <div className="divide-y-2 divide-neutral-600">
              {webhooks.map((webhook: IWebhook, index: number) => {
                return (
                  <div key={index} className="py-5">
                    <div className="flex flex-col items-start">
                      <label>URL</label>
                      <div className="flex gap-3 items-center w-full">
                        <div className="w-full flex-shrink-0">
                          <div className="w-full p-2 my-1 rounded-md bg-neutral-700 border border-neutral-600 outline-none">
                            {webhook.url}
                          </div>
                        </div>
                        <Copy text={webhook.url} />
                      </div>
                    </div>
                    <div className="flex flex-col items-start mt-8">
                      <label>Signature Webhook Key</label>
                      <div className="flex gap-3 items-center w-full">
                        <div className="w-full flex-shrink-0">
                          <div className="w-full p-2 my-1 rounded-md bg-neutral-700 border border-neutral-600 outline-none">
                            {webhook.signature.replaceAll(webhook.signature.slice(-28), "*".repeat(28))}
                          </div>
                        </div>
                        <Copy text={webhook.signature} />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col items-start">
                  <label>Enter Webhook URL</label>
                  <div className="flex gap-x-3 w-full mt-3">
                    <div className="flex-shrink-0 w-full">
                      <input
                        type="text"
                        placeholder="https://example.com/my-webhook"
                        {...getFieldProps("url")}
                        className="w-full p-2 rounded-md bg-neutral-700 border border-neutral-600 outline-none"
                      />
                      {errors.url && touched.url ? <div className="text-red-500 text-xs">{errors.url}</div> : null}
                    </div>
                    <button className="flex-shrink-0">
                      <Button label={"Add webhook"} id={""} className="mb-0" loader={loading} />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </section>
      </div>
    </div>
  );
}

export default WebhookForm;
