import { useState } from "react";
import { Routes, Route } from "react-router-dom";
import ApiKeys from "./ApiKeys";
import Webhooks from "./Webhooks";

const Developers = () => {

  return (
    <>
      <section className='font-manrope h-full text-white'>
        <Routes>
          <Route path="/webhooks" element={<Webhooks />} />
          <Route path="/apikeys" element={<ApiKeys />} />
        </Routes>
      </section>
    </>
  )
}

export default Developers;