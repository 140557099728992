import BankIcon from "../../assets/BankIcon";
import WalletIcon from "../../assets/WalletIcon";

interface Props {
	onClick: () => void;
}

function Send({ onClick }: Props) {
	return (
		<div className="font-sans">
			<div className="border-b border-atlas_orange">
				<button className={`flex-1 py-3 px-20 rounded-tl-lg bg-atlas_orange`}>Send Money To</button>
			</div>

			<div className="border-b border-l border-r border-atlas_orange py-10">
				<div className="border border-solid border-white/50 mx-10 rounded-lg p-3 cursor-pointer" onClick={onClick}>
					<div className="flex gap-x-[10px] items-center">
						<div>
							<BankIcon />
						</div>
						<div>Bank Account</div>
					</div>
					<div className="mt-[10px] text-atlas_slategrey text-xs">Send money to another user’s bank account.</div>
				</div>

				{/* <div className="border border-solid border-white/50 mx-10 my-[10px] rounded-lg p-3 cursor-pointer">
					<div className="flex gap-x-[10px] items-center">
						<div>
							<WalletIcon />
						</div>
						<div>Atlax Wallet</div>
					</div>
					<div className="mt-[10px] text-atlas_slategrey text-xs">Send Money To Atlax Account</div>
				</div> */}
			</div>
		</div>
	);
}

export default Send;
