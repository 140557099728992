import { useState, useEffect } from "react";
import { useAppContext } from "../../Context/AppContext";
import { toast } from "alert";
import { useNavigate } from "react-router-dom";
import Spinner from "../../assets/spinner";

const CreateWallet = () => {
  const { sendRequest } = useAppContext();
  const navigate = useNavigate();
  const [createWalletLoading, setCreateWalletLoading] = useState<boolean>(false);
  const [currencies, setCurrencies] = useState<{ id: number; Code: string; Name: string }[]>([]);
  const [selectedCurrency, setSelectedCurrency] = useState("");

  const fetchCurrencies = async () => {
    try {
      const response = await sendRequest("GET", "/currencies", null);
      setCurrencies(response.data.data);
    } catch (error) {
      toast.error("Error fetching currencies");
      // Optionally set a fallback or empty array
      setCurrencies([]);
    }
  };

  // TODO: use formik here and validate the currency properly.
  // also make sure the error returned from the backend is displayed properly
  const CreateWallet = () => {
    if (!selectedCurrency) {
      toast.error("select a currency");
      return;
    }
    setCreateWalletLoading(true);
    sendRequest("POST", "/ledgers", {
      currency_code: selectedCurrency,
    })
      .then((response: any) => {
        navigate(0);
      })
      .catch((error: any) => {
        toast.error("Error creating wallet");
      })
      .finally(() => {
        setCreateWalletLoading(false);
      });
  };

  useEffect(() => {
    fetchCurrencies();
  }, []);

  return (
    <div className="font-sans ">
      <div className="border-b border-atlas_orange">
        <button className={`flex-1 py-3 px-10 rounded-tl-lg bg-atlas_orange`}>Create new wallet account</button>
      </div>

      <div className="p-8 space-y-5">
        <div className="">Select currency</div>
        <div className="">
          <select
            value={selectedCurrency}
            onChange={(e) => {
              setSelectedCurrency(e.target.value);
            }}
            className="w-full border border-solid border-white/50  rounded-lg p-3 cursor-pointer bg-atlas_gray text-white"
          >
            <option value="" disabled hidden>
              Select a currency
            </option>
            {currencies.length > 0 ? (
              currencies.map((currency) => (
                <option key={currency.id} value={currency.Code}>
                  {currency.Name} ({currency.Code})
                </option>
              ))
            ) : (
              <option disabled>No currencies available</option>
            )}
          </select>
        </div>

        {/*<div className="flex justify-between mx-10 my-4 items-center">
				<p>Set as default wallet</p>
				<div className="flex items-center">
					<label className="relative flex cursor-pointer items-center rounded-full p-3" data-ripple-dark="true">
						<input
							type="checkbox"
							id="t_and_c"
							className="peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-atlas_orange transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-slate-400 before:opacity-0 before:transition-opacity checked:border-atlas_orange checked:bg-atlas_orange checked:before:bg-slate-400 hover:before:opacity-10"
						/>
						<span className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="h-3.5 w-3.5"
								viewBox="0 0 20 20"
								fill="currentColor"
								stroke="currentColor"
								strokeWidth="1"
							>
								<path
									fillRule="evenodd"
									d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
									clipRule="evenodd"
								></path>
							</svg>
						</span>
					</label>
				</div>
			</div>*/}

        <div className="flex justify-center w-full">
          <button onClick={CreateWallet} disabled={createWalletLoading} className="btn">
            {createWalletLoading ? <Spinner /> : "Create Wallet"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateWallet;
