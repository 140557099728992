import { useState } from "react";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";


function PasswordPage() {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <section className="px-16 py-6 font-manrope">
      <div className="flex pb-5 border-b border-neutral-700 items-center text-white">
        <h2 className="text-2xl">Password</h2>
      </div>
      <main className="flex justify-between space-x-10 py-5">
        <div className="flex flex-col justify-center w-1/2 space-y-8">
          <div>
            <label
              htmlFor="password"
              className="text-atlas_black dark:text-white text-lg font-normal block"
            >
              Current password
            </label>
            <div className="relative flex items-center max-w-md">
              <input
                type={showPassword ? "text" : "password"}
                className="px-3 py-3 rounded-md w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate"
                required
                inputMode="text"
                placeholder="Placeholder"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible className="size-6 text-black dark:text-white" color="" />
                ) : (
                  <AiOutlineEye className="size-6 text-black dark:text-white" color="" />
                )}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="text-atlas_black dark:text-white text-lg font-normal block"
            >
              New password
            </label>
            <div className="relative flex items-center max-w-md">
              <input
                type={showPassword ? "text" : "password"}
                className="px-3 py-3 rounded-md w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate"
                required
                inputMode="text"
                placeholder="Placeholder"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible className="size-6 text-black dark:text-white" color="" />
                ) : (
                  <AiOutlineEye className="size-6 text-black dark:text-white" color="" />
                )}
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="password"
              className="text-atlas_black dark:text-white text-lg font-normal block"
            >
              Confirm new password
            </label>
            <div className="relative flex items-center max-w-md">
              <input
                type={showPassword ? "text" : "password"}
                className="px-3 py-3 rounded-md w-full text-atlas_black dark:text-white bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate"
                required
                inputMode="text"
                placeholder="Placeholder"
              />
              <div
                className="absolute inset-y-0 right-0 pr-3 flex space-x-2 items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <AiOutlineEyeInvisible className="size-6 text-black dark:text-white" color="" />
                ) : (
                  <AiOutlineEye className="size-6 text-black dark:text-white" color="" />
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-end max-w-md">
            <button className="btn w-fit">Confirm new password</button>
          </div>
        </div>
        <div className="w-1/2 flex justify-center">
          <aside className="p-5 flex flex-col space-y-5 max-w-sm bg-atlas_gray rounded-lg font-manrope">
            <h4 className="text-lg font-semibold">Rules for password</h4>
            <p className="brightness-50 text-lg font-thin">To create a new password, you have to meet the following requirements</p>
            <ul className="brightness-50 text-lg font-thin list-disc list-inside">
              <li>At least 8 characters</li>
              <li>Special character !@#$-_/</li>
              <li>An uppercase letter</li>
              <li>A lowercase letter</li>
              <li>A number</li>
            </ul>
          </aside>
        </div>
      </main>
    </section>
  )
}

export default PasswordPage;