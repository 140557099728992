import { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import TableComponent from "../TableComponent";
import Modal from "../Modals/Modal";
import RightSidebar from "./RightSidebar";
import BalanceCard from "../BalanceCard";
import { useDashboardContext } from "../../Context/DashboardContext";
import { useAppContext } from "../../Context/AppContext";
import { ITransaction } from "../../schema/transaction";
import AllWallets from "../WalletsDropdown";
import formatAmount from "../../util/money";
// import { ChartOptions } from "chart.js";
// import gtbpic from "../../assets/gtbank.svg";
// import moniepic from "../../assets/moniepoint.jpeg";
// import sterling from "../../assets/sterlingicon.jpeg";
// import { TbDownload } from "react-icons/tb";
// import { MdOutlineShare } from "react-icons/md";
// import placeholder from "../../assets/placeholder.svg";
// import arrowLeft from "../../assets/arrow-left.svg";
// import arrowRight from "../../assets/arrow-right.svg";
// import { FiEye } from "react-icons/fi";
// import { FiEyeOff } from "react-icons/fi";
// import Ellipse from "../../assets/Ellipse.svg";
// import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
// import BulkMenu from "../BulkMenu";
// import { useAppContext } from "../../Context/AppContext";

const columns = [
  { field: "created_at", headerName: "Date" },
  { field: "bank_name", headerName: "Bank" },
  { field: "beneficiary_name", headerName: "Beneficiary name" },
  { field: "reference", headerName: "Reference" },
  { field: "amount", headerName: "Amount" },
  { field: "status", headerName: "Status" },
  // Add more columns as needed
];

/*
const data = {
  labels: [
    "NGN",
    // "USD",
    // "KES",
    // "Others"
  ],
  datasets: [
    {
      data: [
        200,
        // 70,
        // 80,
        // 40
      ],
      backgroundColor: [
        "rgb(213,176,113)",
        // "rgb(235,87,87)",
        // "rgb(189,189,189)",
        // "rgb(47,128,237)",
      ],
      borderColor: "transparent",
      hoverOffset: 4,
    },
  ],
};

const options: ChartOptions<"doughnut"> = {
  cutout: "70%",
  radius: 45,
  plugins: {
    legend: {
      display: true,
      position: "left",
      onClick: () => {},
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        padding: 6,
        font: {
          size: 12,
          weight: "normal",
        },
        color: "white",
        pointStyle: "rectRounded",
      },
    },
  },
};
*/

const DashboardPage = () => {
  ChartJS.register(ArcElement, Tooltip, Legend);
  const { sendRequest } = useAppContext();
  const { wallets, isDashboardVerificationComplete } = useDashboardContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  // const [viewBalance, setViewBalance] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  function fetchTransactions(ledgerID: string) {
    sendRequest("GET", `/ledgers/${ledgerID}/transactions`, null)
      .then((res) => {
        // console.log(res);
        if (res.data.data) setTransactions(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (wallets.length) {
      fetchTransactions(wallets[0].id);
    }
  }, [wallets]);

  return (
    <div className="lg:flex">
      <div
        className={`grow lg:basis-2/3 ${isDashboardVerificationComplete ? "" : "filter blur-md pointer-events-none"}`}
      >
        <div className="p-5 box-border space-y-5">
          <div className="md:w-1/2">
            <BalanceCard wallets={wallets} />
            <div className="flex items-center gap-4 text-nowrap mt-4">
              <button
                onClick={() => {
                  handleTitle("Addmoney");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Add Money
              </button>
              <button
                onClick={() => {
                  handleTitle("Send");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Send
              </button>
              {/* <button
                    onClick={() => {
                      handleTitle("Convert");
                      handleOpenModal();
                    }}
                    className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    Convert
                  </button>
                  <button
                    className="text-white py-[2px] bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
                  >
                    <BulkMenu />
                  </button> */}
            </div>
            {/*top part ends here*/}

            {/*<div className="hidden md:block w-3/6 h-fit flex-auto px-6 py-4 bg-atlas_gray rounded-lg border-2 border-solid border-white/10">
                <h1 className="text-white text-xl font-sans">Asset distribution</h1>
                <div className="">
                  <div className="">
                    <Doughnut data={data} options={options} />
                  </div>
                </div>
              </div>*/}
          </div>

          <div>
            <TableComponent
              header={
                <section className="flex items-center p-5 text-gray-100 rounded-t-xl justify-between bg-neutral-800 border-2 border-neutral-700">
                  <div>
                    <h2 className="text-lg cursor-default select-none">Recent Transactions</h2>
                  </div>
                  <div className="flex items-center justify-between space-x-7">
                    {/*<button title="Download" className="cursor-pointer ">
                    <TbDownload className="size-5 text-neutral-400 hover:text-neutral-200" />
                  </button>
                  <button title="Share" className="cursor-pointer">
                    <MdOutlineShare className="size-5 text-neutral-400 hover:text-neutral-200" />
                  </button>*/}
                    <AllWallets />
                  </div>
                </section>
              }
              columns={columns}
              data={transactions?.map((t) => {
                return {
                  ...t,
                  amount: formatAmount(t.amount / 100, t.currency_code),
                  created_at: new Date(t.created_at * 1000).toLocaleString(),
                };
              })}
            />
          </div>

          {/* <div className="my-10 underline pb-2 text-white flex justify-center items-center">
              <button
                className="text-sm font-sans text-white"
                onClick={handleSeeAllClick}
              >
                See All
              </button>
            </div> */}

          {/* <div className="my-5">
              <div className="flex justify-between my-4 text-white">
                <p>Recent Transaction</p>
              </div>
              <TableComponent columns={columns} />
            </div> */}

          <Modal isOpen={isModalOpen} onClose={handleCloseModal} type={contentType} />
        </div>
      </div>

      <div className="hidden lg:basis-1/3 lg:flex border-l border-atlas_gray p-5">
        <RightSidebar />
        {/* some changes */}
      </div>
    </div>
  );
};

export default DashboardPage;
