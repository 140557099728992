import React from "react";
import { RxEnvelopeClosed } from "react-icons/rx";
import { FaXTwitter, FaWhatsapp } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

interface ShareProps {
  onclose: () => void;
}

const Share: React.FC<ShareProps> = ({ onclose }) => {
	const emailSubject = "Atlaxchange Bank Details";
	const items = [
        "Account Name: John Doe",
        "Account Number: 1234567890",
        "Bank: Atlas Bank",
    ];

	return (
		<div className="p-14 font-manrope flex flex-col gap-5">
			<div className="text-center font-normal">Share account details</div>

			<div className="flex items-center justify-center gap-5 cursor-pointer">
				<div className="bg-atlas_orange rounded-full p-2">
					{" "}
					<Link title="facebook.com" to="https://x.com/Atlaxchange?t=2v29AglyqlE61Jj7bNyhlg&s=09">
						<FaFacebookF size={20} />
					</Link>
				</div>

				<div className="bg-atlas_orange rounded-full p-2">
					<a
						title="X.com"
						href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(items.join('\n'))}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaXTwitter size={20} />
					</a>
				</div>

				{/* Email */}
				<div className="bg-atlas_orange rounded-full p-2">
					<a
						title="Email"
						href={`mailto:hi@atlaxchange.com?subject=${encodeURIComponent(
							emailSubject
						)}&body=${encodeURIComponent(items.join('\n'))}`}
					>
						<RxEnvelopeClosed size={20} />
					</a>
				</div>

				{/* WhatsApp */}
				<div className="bg-atlas_orange rounded-full p-2">
					<a
						title="WhatsApp"
						href={`https://wa.me/?text=${encodeURIComponent(items.join('\n'))}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						<FaWhatsapp size={20} />
					</a>
				</div>
			</div>
			<div className="flex items-center justify-center">
				<button onClick={onclose} className="bg-atlas_orange text-white py-2 px-5 border-2 border-solid text-sm border-white/10 rounded-lg ">
					Done
				</button>
			</div>
		</div>
	);
};

export default Share;
