import { FiFilter } from "react-icons/fi";
import { MdOutlineShare } from "react-icons/md";
import AllWallets from "./WalletsDropdown";
import TableNav from "./TableNav";

const headers = [
  {
    name: 'Date',
    date: '11 Jul, 4:30am',
  },
  {
    name: 'Bank',
    date: '11 Jul, 4:30am',
  },
  {
    name: 'Status',
    date: '11 Jul, 4:30am',
  },
  {
    name: 'Sending',
    date: '11 Jul, 4:30am',
  },
  {
    name: 'Amount',
    date: '11 Jul, 4:30am',
  },
  {
    name: 'Rate',
    date: '11 Jul, 4:30am',
  },
]

function HistoryTable() {
  return (
    <>
      <section className="flex items-center p-5 text-gray-100 rounded-t-xl justify-between bg-neutral-800 border-2 border-neutral-700">
        <div className="flex items-center justify-between space-x-5">
          <h2 className="text-lg cursor-default select-none">Transaction History</h2>
          <FiFilter title="Filter" className="text-atlas_orange hover:brightness-90 cursor-pointer" />
        </div>
        <div className="flex items-center justify-between space-x-7">
          <AllWallets />
          <a className="text-base text-atlas_orange underline cursor-pointer hover:brightness-90">
            See transaction history for all wallets
          </a>
        </div>
      </section>
      <table className="min-w-full font-manrope table-auto border-2 border-neutral-700 rounded-b-xl border-separate">
        <thead className="border-b">
          <tr className="text-lg text-white/40">
            {headers.map((header, index) => (
              <th key={index} className="px-6 py-4 font-normal text-left">
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr></tr>
        </tbody>
      </table>
      <TableNav />
    </>
  )
}

export default HistoryTable;
