import { GiPrivateFirstClass } from "react-icons/gi";
import { LuPenLine } from "react-icons/lu";
import { HiOutlineTrash } from "react-icons/hi";
import { useState } from "react";


const people = [
  { username: 'Michael', email: 'MJohnson27@gmail.com', date: '12/12/2022', role: 'Super Admin' },
  { username: 'David', email: 'David1927@gmail.com', date: '16/12/2022', role: 'Staff' },
  { username: 'Lawal', email: 'Adelaw@gmail.com', date: '16/12/2022', role: 'Staff' },
  // More people...
]

function UsersTable() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">

      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
              <table className="min-w-full">
                <thead className="bg-transparent text-lg">
                  <tr>
                    <th scope="col" className="py-3.5 pr-3 text-left brightness-50 text-gray-100">
                      Userame
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left brightness-50 text-gray-100">
                      Email
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left brightness-50 text-gray-100">
                      Date added
                    </th>
                    <th scope="col" className="px-3 py-3.5 text-left brightness-50 text-gray-100">
                      Role
                    </th>
                    <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-transparent">
                  {people.map((person) => (
                    <tr key={person.email}>
                      <td className="whitespace-nowrap py-4 pr-3 font-medium text-gray-100">
                        {person.username}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-gray-200">{person.email}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-gray-200">{person.date}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-gray-200">
                        <p className="px-3 py-1 w-fit cursor-default bg-atlas_orange text-white rounded-sm">{person.role}</p>
                      </td>
                      <td className="relative flex items-center space-x-5 whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                        <LuPenLine className="p-2 size-8 cursor-pointer text-black rounded-full bg-white hover:brightness-90" />
                        <button className="px-3 py-1 inline-flex items-center bg-red-600 text-gray-100 rounded-md cursor-pointer hover:brightness-90">
                          <HiOutlineTrash className="size-7 pr-2" />
                          Disable User
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsersTable;