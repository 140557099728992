export default function () {
	return (
		<svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M3.125 5.62538V10.6254M5.9375 5.62538V10.6254M9.0625 5.62538V10.6254M11.875 5.62538V10.6254M1.875 11.6254V12.1254C1.875 12.4754 1.875 12.6504 1.94312 12.7841C2.00304 12.9017 2.09866 12.9974 2.21626 13.0572C2.34995 13.1254 2.52497 13.1254 2.875 13.1254H12.125C12.4751 13.1254 12.6501 13.1254 12.7838 13.0572C12.9014 12.9974 12.9969 12.9017 13.0569 12.7841C13.125 12.6504 13.125 12.4754 13.125 12.1254V11.6254C13.125 11.2754 13.125 11.1003 13.0569 10.9666C12.9969 10.8491 12.9014 10.7534 12.7838 10.6935C12.6501 10.6254 12.4751 10.6254 12.125 10.6254H2.875C2.52497 10.6254 2.34995 10.6254 2.21626 10.6935C2.09866 10.7534 2.00304 10.8491 1.94312 10.9666C1.875 11.1003 1.875 11.2754 1.875 11.6254ZM7.28306 1.92358L2.65807 2.95137C2.37866 3.01345 2.23895 3.0445 2.13466 3.11963C2.04268 3.1859 1.97046 3.27594 1.92572 3.38011C1.875 3.4982 1.875 3.64132 1.875 3.92755V4.62538C1.875 4.97541 1.875 5.15043 1.94312 5.28412C2.00304 5.40172 2.09866 5.49733 2.21626 5.55726C2.34995 5.62538 2.52497 5.62538 2.875 5.62538H12.125C12.4751 5.62538 12.6501 5.62538 12.7838 5.55726C12.9014 5.49733 12.9969 5.40172 13.0569 5.28412C13.125 5.15043 13.125 4.97541 13.125 4.62538V3.92755C13.125 3.64132 13.125 3.49821 13.0743 3.38011C13.0296 3.27594 12.9573 3.1859 12.8653 3.11963C12.7611 3.0445 12.6214 3.01345 12.3419 2.95137L7.71694 1.92358C7.636 1.9056 7.5955 1.8966 7.55463 1.89302C7.51825 1.88983 7.48175 1.88983 7.44537 1.89302C7.4045 1.8966 7.364 1.9056 7.28306 1.92358Z"
				stroke="#FF8205"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
