import React, { useState, useEffect, useRef } from "react";
import fileupload from "../../assets/upload.svg";
import { useAppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface FileUploadProps {
  onNext: () => void;
  idType?: {
    id_types: Record<string, { label: string }>;
    country: string;
  };
}

function fileToBase64(file: Blob) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

const FileUpload = ({ onNext, idType }: FileUploadProps) => {
  const [frontPageImage, setFrontPageImage] = useState<string | null>(null);
  const [backPageImage, setBackPageImage] = useState<string | null>(null);
  const [frontPageFile, setFrontPageFile] = useState<File | null>(null);
  const [backPageFile, setBackPageFile] = useState<File | null>(null);
  const [activeButton, setActiveButton] = useState("button1");
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [IdType, setIdType] = useState<string | null>("");
  const button1Ref = useRef<HTMLButtonElement>(null);
  const button2Ref = useRef<HTMLButtonElement>(null);
  const { sendRequest } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    // Update the underline width based on the active button
    if (activeButton === "button1" && button1Ref.current) {
      setUnderlineWidth(button1Ref.current.offsetWidth);
    } else if (activeButton === "button2" && button2Ref.current) {
      setUnderlineWidth(button2Ref.current.offsetWidth);
    }
  }, [activeButton]);

  function shortenString(str: string, length: number) {
    if (str.length <= length) {
      return str;
    } else {
      return str.slice(0, length) + "...";
    }
  }

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setIdType(event.target.value);
  };

  const handleFrontPageImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setFrontPageFile(file);
      setFrontPageImage(file.name);
    }
  };

  const handleBackPageImageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      setBackPageFile(file);
      setBackPageImage(file.name);
    }
  };

  const handleUpload = async () => {
    // Validate that the front image is selected, as it's required
    if (!frontPageFile) {
      console.error("Front page image is required.");
      return;
    }
    try {
      const images: Array<{
        image_type_id: number;
        image: string;
        file_name: string;
      }> = [];

      // Convert and add the front page image
      const frontBase64 = await fileToBase64(frontPageFile);
      images.push({
        image_type_id: 2,
        image: frontBase64,
        file_name: frontPageFile.name,
      });

      // Convert and add the back page image if available
      if (backPageFile) {
        const backBase64 = await fileToBase64(backPageFile);
        images.push({
          image_type_id: 2,
          image: backBase64,
          file_name: backPageFile.name,
        });
      }

      const payload = {
        country: idType?.country, // from props
        id_type: IdType ? IdType.toUpperCase() : "", // from props
        images,
      };

      // Replace 'YOUR_UPLOAD_ENDPOINT' with your actual endpoint URL
      await sendRequest("POST", "/kyc/verify", payload);
      toast.success("Upload successful");
      navigate("/dashboard");
    } catch (error) {
      toast.error("Upload failed. Please try again.");
    }
  };

  return (
    <div className="mx-5 my-7">
      <div className="flex justify-end mt-3">
        <form className="w-7/12">
          <select
            id="countries"
            className="bg-atlas_gray border border-gray-300/50 text-sm text-white rounded-lg focus:outline-none focus:border-atlas_orange  block w-full p-1.5 "
            onChange={handleSelectChange}
          >
            <option value="" selected>
              Select an ID card
            </option>
            {idType &&
              Object.entries(idType.id_types).map(([key, { label }]) => (
                <option key={key} value={key}>
                  {label}
                </option>
              ))}
          </select>
        </form>
      </div>

      <div className="flex flex-col mt-5">
        <div className="flex justify-center">
          <button
            ref={button1Ref}
            className={`px-4 py-2 text-white text-xs`}
            onClick={() => setActiveButton("button1")}
          >
            Upload Front Page
          </button>
          <button
            ref={button2Ref}
            className={`px-4 py-2 text-white text-xs`}
            onClick={() => setActiveButton("button2")}
          >
            Upload Back Page
          </button>
        </div>

        <div className="flex justify-center w-full">
          <div
            className={`h-0.5 px-4 ${
              activeButton === "button1" ? "bg-white" : "bg-gray-300/50"
            } transition-all`}
            style={{
              width: `${underlineWidth}px`,
              transition: "width 0.3s ease-in-out",
            }}
          ></div>
          <div
            className={`h-0.5 px-4 ${
              activeButton === "button2" ? "bg-white" : "bg-gray-300/50"
            } transition-all`}
            style={{
              width: `${underlineWidth}px`,
              transition: "width 0.3s ease-in-out",
            }}
          ></div>
        </div>
      </div>

      <div className="border-2 border-gray-300/10 border-dashed rounded-xl my-5 px-8 py-12">
        <div className="flex justify-center items-center">
          <img src={fileupload} alt="fileupload" className="w-15" />
        </div>

        <form>
          <div className="flex justify-center items-center mt-5">
            <label
              htmlFor="img"
              className="bg-atlas_orange py-1 px-14 rounded-md text-black text-sm font-bold text-center cursor-pointer font-sans"
              onClick={() => document.getElementById("getFile")?.click()}
            >
              Upload
              <input
                type="file"
                accept="image/*"
                data-max-size="1048"
                id="getFile"
                className="hidden"
                onChange={(event) => {
                  if (activeButton === "button1") {
                    handleFrontPageImageChange(event);
                  } else if (activeButton === "button2") {
                    handleBackPageImageChange(event);
                  }
                }}
              />
            </label>
          </div>

          <div className="flex flex-col items-center">
            {frontPageImage && (
              <div className="mt-2 text-sm text-white">
                {`Front Page ${shortenString(frontPageImage, 10)}`}
              </div>
            )}
            {backPageImage && (
              <div className="mt-2 text-sm text-white">
                {`Back Page ${shortenString(backPageImage, 10)}`}
              </div>
            )}
          </div>
        </form>
      </div>

      <div className="flex lg:justify-end justify-center space-x-4">
        {/* Optional: Trigger upload before proceeding */}
        <button
          onClick={handleUpload}
          className="py-2 px-7 rounded-md text-black font-semibold mt-4 bg-atlas_orange"
        >
          Upload Images
        </button>
        <button
          onClick={onNext}
          className="py-2 px-7 rounded-md text-black font-semibold mt-4 bg-atlas_orange"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default FileUpload;
