import React, { useState } from "react";
import Ellipse from "../../assets/Ellipse.svg";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Modal from "../Modals/Modal";
import BulkMenu from "../BulkMenu";
import BalanceCard from "../BalanceCard";
import HistoryTable from "../HistoryTable";
import { useDashboardContext } from "../../Context/DashboardContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const WalletPage = () => {
  const { wallets } = useDashboardContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");
  const [selected, setSelected] = useState(0);

  const handleItemClick = (id: any) => {
    setSelected(id); // Set the clicked item as active
  };

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const [viewBalance, setViewBalance] = useState(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  const toggleViewBalance = () => {
    setViewBalance(!viewBalance);
  };

  const labels = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat",];

  const options = {
    scales: {
      y: {
        ticks: {
          display: true, // Hides the tick labels on the y-axis
        },
        grid: {
          display: true, // Hides the grid lines for the y-axis
          color: "rgba(255,255,255 , 0.2)",
        },
      },
      // Optionally, do the same for the x-axis if needed
      x: {
        ticks: {
          font: {
            family: "work sans",
            size: 12, // Specify the font family for x-axis tick labels
          },
          display: true,
          color: "rgba(255,255,255 , 0.7)", // Hides the tick labels on the x-axis
        },
        grid: {
          display: true, // unhides the grid lines for the x-axis
          color: "rgba(255,255,255 , 0.2)",
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: [20, 100, 20, 310, 100, 350, 40,],
        fill: false,
        borderColor: "#FF8205",
        pointBackgroundColor: "#FF8205",
        // Use a function for the backgroundColor to apply the gradient
        backgroundColor: function (context: { chart: any }) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return null;
          }
          const gradient = ctx.createLinearGradient(
            0,
            chartArea.bottom,
            0,
            chartArea.top
          );
          gradient.addColorStop(0, "rgba(46,46,46, 0.7)"); // black
          gradient.addColorStop(1, "rgba(213,176,113, 0.9)"); // red

          return gradient;
        },
        tension: 0,
      },
    ],
  };

  return (
    <div className="flex justify-center">
      <div className="mx-0 md:mx-6 my-4 font-sans lg:w-4/5 w-full">
        {/* <p className="text-white pb-4 font-sans font-extralight text-sm">
        Wallet {">"}{" "}
      </p> */}

        <div className="flex flex-row md:justify-between gap-3 mt-3 md:mt-6 mb-10">
          <div className="w-1/2">
            <BalanceCard wallets={wallets}/>

            <div className="flex space-x-2 items-center justify-between text-nowrap mt-4">
              <button
                onClick={() => {
                  handleTitle("Trade");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Add Money
              </button>
              <button
                onClick={() => {
                  handleTitle("Send");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Send
              </button>
              <button
                onClick={() => {
                  handleTitle("Convert");
                  handleOpenModal();
                }}
                className="text-white py-2 px-5 bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                Convert
              </button>
              <button
                className="text-white py-[2px] bg-atlas_gray border-2 border-solid text-sm hover:bg-atlas_orange border-white/10 rounded-lg"
              >
                <BulkMenu />
              </button>
            </div>
          </div>
          <div className="lgw-5/12 md:w-5/12 w-full flex-auto pt-4 p-2 rounded-md bg-atlas_gray">
            <div className="w-full">
              <Line options={options} data={data} />
            </div>
          </div>
        </div>
        {/*top part ends here*/}

        <HistoryTable />

        {/* <div className="lg:flex md:flex block gap-4 my-14 text-white font-sans">
          <div className="lg:w-7/12 w-full flex-auto ">
            <p>My Assets</p>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-4 cursor-pointer">
              {AssetsData.map((item, index) => (
                <div key={index} className={`w-full bg-atlas_gray rounded-lg my-4 px-5 py-5 ${selected === index ? 'border border-solid border-atlas_gold' : ' '}`} onClick={() => handleItemClick(index)}>
                  <div className="flex justify-between">
                    <div className="flex items-center gap-2">
                      <img src={Ellipse} alt="ellipse" className="w-5 h-5" />
                      <p className="text-xs">{item.description}</p>
                    </div>
                    <div
                      className={`py-1 px-1 flex justify-center rounded-md item-center text-white font-semibold text-sm ${item.status === "increase"
                        ? "bg-atlas_green/20"
                        : "bg-red-500/20"
                        }`}
                    >
                      <div className="flex items-center">
                        {item.status === "increase" ? (
                          <MdArrowUpward className="text-atlas_green" />
                        ) : (
                          <MdArrowDownward className="text-red-500" />
                        )}
                      </div>
                      <p
                        className={`${item.status === "increase"
                          ? "text-atlas_green"
                          : "text-red-500"
                          } font-normal text-xs`}
                      >
                        {item.percentage}%
                      </p>
                    </div>
                  </div>

                  <p className="pt-3 font-semibold">{item.amount}</p>
                  <p className="font-extralight text-xs text-white/50">
                    {item.usdt}{" USDT"}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="w-5/12 flex-auto">
            <p>Recent Activities</p>

            <div className="bg-atlas_gray w-full h-full my-4 rounded-lg">

            </div>
          </div>
        </div> */}

        <Modal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          type={contentType}
        />
      </div>

      {/* <div className="hidden lg:flex border-l border-atlas_gray flex-grow">
        <RightSidebar />
      </div> */}

    </div>
  );
};

export default WalletPage;
