import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo.svg";
import emoji from "../../assets/icon _Happy and Smile_.svg";
import { UploadButton } from "../button";
import { KybFormContext } from "../../Context/KybFormContext";

interface Props {
  onComplete: (step: string) => void;
}

const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
};

const BusinessDoc = ({ onComplete }: Props) => {
  const { updateField } = useContext(KybFormContext);

  const [upload, setUpload] = useState<{
    board_resolution: File | null;
    cac_doc: File | null;
    company_profile: File | null;
    memorandum: File | null;
    year: File | null;
  }>({
    board_resolution: null,
    cac_doc: null,
    company_profile: null,
    memorandum: null,
    year: null,
  });

  function shortenString(str: string, maxLength: number) {
    if (str.length <= maxLength) return str;

    const lastDotIndex = str.lastIndexOf(".");
    if (lastDotIndex === -1) {
      return str.slice(0, maxLength) + "...";
    }

    const extension = str.slice(lastDotIndex);
    const spaceForName = maxLength - extension.length - 3;

    if (spaceForName <= 0) {
      return "..." + extension;
    }

    return str.slice(0, spaceForName) + "..." + extension;
  }

  const handleFileSelect = async (key: string, file: File | null) => {
    setUpload((prev) => ({
      ...prev,
      [key]: file,
    }));

    if (file) {
      try {
        const base64 = await fileToBase64(file);
        let contextField = "";
        switch (key) {
          case "board_resolution":
            contextField = "boardResolution";
            break;
          case "cac_doc":
            contextField = "cacDocument";
            break;
          case "company_profile":
            contextField = "companyProfile";
            break;
          case "memorandum":
            contextField = "memorandumOfAgreement";
            break;
          case "year":
            contextField = "yearOfAllocation";
            break;
          default:
            break;
        }
        if (contextField) {
          updateField(contextField, base64);
        }
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  const handleFileRemove = (key: string) => {
    setUpload((prev) => ({
      ...prev,
      [key]: null,
    }));

    let contextField = "";
    switch (key) {
      case "board_resolution":
        contextField = "boardResolution";
        break;
      case "cac_doc":
        contextField = "cacDocument";
        break;
      case "company_profile":
        contextField = "companyProfile";
        break;
      case "memorandum":
        contextField = "memorandumOfAgreement";
        break;
      case "year":
        contextField = "yearOfAllocation";
        break;
      default:
        break;
    }
    if (contextField) {
      updateField(contextField, null);
    }
  };

  // Determine if all required files are uploaded
  const allFilesUploaded = upload.board_resolution && upload.cac_doc &&
                           upload.company_profile && upload.memorandum &&
                           upload.year;

  return (
    <div className="bg-white dark:bg-atlas_black text-black dark:text-white font-manrope ">
      <div>
        <div className="flex lg:justify-end justify-between px-10 pt-6 ">
          <Link to="/" className="flex items-center justify-between lg:hidden">
            <img
              className="w-12 h-12 p-2 cursor-pointer fill-atlas_orange"
              src={logo}
              alt="logo"
            />
          </Link>
          <p className="flex items-center text-white text-sm">
            We really need this part &nbsp; <img src={emoji} alt="emoji" />{" "}
          </p>
        </div>
        <div className="lg:w-7/12 w-full py-14 lg:pl-20 px-10">
          <h3 className="text-2xl text-white font-semibold pt-8 pb-2">
            Business Documents
          </h3>
          <form className="pt-10 font-sans">
            <div className="grid ">
              {/* Board Resolution */}
              <div className="">
                <div className="flex justify-between">
                  <p className="text-white font-normal text-sm block py-1">
                    Board resolution <span className="text-red-500">*</span>
                  </p>
                  {upload.board_resolution ? (
                    <div className="flex items-center">
                      <p className="text-atlas_orange font-normal text-sm ">
                        {shortenString(upload.board_resolution?.name || "", 20)}
                      </p>
                      <button
                        type="button"
                        className="text-red-500 ml-2 text-sm"
                        onClick={() => handleFileRemove("board_resolution")}
                      >
                        X
                      </button>
                    </div>
                  ) : null}
                </div>
                <UploadButton
                  label="Upload Document"
                  id="board_resolution"
                  onFileSelect={(file) =>
                    handleFileSelect("board_resolution", file)
                  }
                />
              </div>

              {/* CAC Document */}
              <div>
                <div className="flex justify-between">
                  <p className="text-white font-normal text-sm block py-1">
                    CAC Document <span className="text-red-500">*</span>
                  </p>
                  {upload.cac_doc ? (
                    <div className="flex items-center">
                      <p className="text-atlas_orange font-normal text-sm ">
                        {shortenString(upload.cac_doc?.name || "", 20)}
                      </p>
                      <button
                        type="button"
                        className="text-red-500 ml-2 text-sm"
                        onClick={() => handleFileRemove("cac_doc")}
                      >
                        X
                      </button>
                    </div>
                  ) : null}
                </div>
                <UploadButton
                  label="Upload Document"
                  id="cac_doc"
                  onFileSelect={(file) => handleFileSelect("cac_doc", file)}
                />
              </div>
            </div>

            {/* Company Profile */}
            <div className="grid">
              <div>
                <div className="flex justify-between">
                  <p className="text-white font-normal text-sm block py-1">
                    Company Profile <span className="text-red-500">*</span>
                  </p>
                  {upload.company_profile ? (
                    <div className="flex items-center">
                      <p className="text-atlas_orange font-normal text-sm ">
                        {shortenString(upload.company_profile?.name || "", 20)}
                      </p>
                      <button
                        type="button"
                        className="text-red-500 ml-2 text-sm"
                        onClick={() => handleFileRemove("company_profile")}
                      >
                        X
                      </button>
                    </div>
                  ) : null}
                </div>
                <UploadButton
                  label="Upload Document"
                  id="company_profile"
                  onFileSelect={(file) =>
                    handleFileSelect("company_profile", file)
                  }
                />
              </div>
            </div>

            {/* Memorandum */}
            <div className="grid ">
              <div>
                <div className="flex justify-between">
                  <p className="text-white font-normal text-sm block py-1">
                    Memorandum <span className="text-red-500">*</span>
                  </p>
                  {upload.memorandum ? (
                    <div className="flex items-center">
                      <p className="text-atlas_orange font-normal text-sm ">
                        {shortenString(upload.memorandum?.name || "", 20)}
                      </p>
                      <button
                        type="button"
                        className="text-red-500 ml-2 text-sm"
                        onClick={() => handleFileRemove("memorandum")}
                      >
                        X
                      </button>
                    </div>
                  ) : null}
                </div>
                <UploadButton
                  label="Upload Document"
                  id="memorandum"
                  onFileSelect={(file) => handleFileSelect("memorandum", file)}
                />
              </div>

              {/* Year of Allocation */}
              <div>
                <div className="flex justify-between">
                  <p className="text-white font-normal text-sm block py-1">
                    Year of allocation <span className="text-red-500">*</span>
                  </p>
                  {upload.year ? (
                    <div className="flex items-center">
                      <p className="text-atlas_orange font-normal text-sm ">
                        {shortenString(upload.year?.name || "", 20)}
                      </p>
                      <button
                        type="button"
                        className="text-red-500 ml-2 text-sm"
                        onClick={() => handleFileRemove("year")}
                      >
                        X
                      </button>
                    </div>
                  ) : null}
                </div>
                <UploadButton
                  label="Upload Document"
                  id="year"
                  onFileSelect={(file) => handleFileSelect("year", file)}
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => onComplete && onComplete("business_doc")}
                disabled={!allFilesUploaded}
                className={`py-2 px-9 rounded-md font-semibold mt-4 ${
                  allFilesUploaded
                    ? "text-white bg-atlas_orange"
                    : "text-white/70 bg-atlas_disabled_orange cursor-not-allowed"
                }`}
              >
                Continue
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BusinessDoc;
