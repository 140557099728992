// src/components/DirectorsForm.tsx
import React, { useState, useContext } from "react";
import { Director } from "../../util/Director";
import { FormState } from "../../util/FormState";
import DirectorForm from "./DirectorForm"; // Child component
import { KybFormContext } from "../../Context/KybFormContext";
import { useAppContext } from "../../Context/AppContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

interface Props {
  onComplete: (step: string) => void;
}

const DirectorInfo: React.FC<Props> = ({ onComplete }) => {
  const { formData, updateField } = useContext(KybFormContext);
  const navigate = useNavigate();
  const { sendRequest } = useAppContext();

  const [formState, setFormState] = useState<FormState>({
    directors: [
      {
        id: 1,
        fullName: "",
        dateOfBirth: "",
        nationality: "",
        bvn: "",
        proofOfIdentity: "passport",
        idImage: null,
        errors: {},
      },
    ],
  });

  // Utility function to calculate age
  const calculateAge = (dob: string): number => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  };

  // Handle input changes for director fields with validation
  const handleDirectorChange = (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;
    const field = name as keyof Director;
    setFormState((prevState: { directors: Director[] }) => ({
      ...prevState,
      directors: prevState.directors.map((director) => {
        if (director.id === id) {
          const updatedDirector = { ...director, [field]: value };
          const errors = { ...director.errors };

          // Field-specific validations
          switch (field) {
            case "fullName":
              if (!value) {
                errors.fullName = "Full Name is required.";
              } else {
                delete errors.fullName;
              }
              break;

            case "dateOfBirth":
              if (!value) {
                errors.dateOfBirth = "Date of Birth is required.";
              } else {
                const age = calculateAge(value);
                if (age < 18) {
                  errors.dateOfBirth =
                    "Director must be at least 18 years old.";
                } else {
                  delete errors.dateOfBirth;
                }
              }
              break;

            case "nationality":
              if (!value) {
                errors.nationality = "Nationality is required.";
              } else {
                delete errors.nationality;
              }
              break;

            case "bvn":
              if (!value) {
                errors.bvn = "BVN is required.";
              } else if (value.length !== 11) {
                errors.bvn = "BVN must be exactly 11 digits.";
              } else if (!/^\d{11}$/.test(value)) {
                errors.bvn = "BVN must contain only numbers.";
              } else {
                delete errors.bvn;
              }
              break;

            case "proofOfIdentity":
              if (!value) {
                errors.proofOfIdentity = "Proof of Identity is required.";
              } else {
                delete errors.proofOfIdentity;
              }
              break;

            default:
              break;
          }

          return { ...updatedDirector, errors };
        }
        return director;
      }),
    }));
  };

  // Handle file selection with validation
  const handleFileSelect = (id: number | string, file: File | null) => {
    if (!file) {
      setFormState((prevState) => ({
        ...prevState,
        directors: prevState.directors.map((director) =>
          director.id === id
            ? {
                ...director,
                idImage: null,
                errors: {
                  ...director.errors,
                  idImage: "Document file is required.",
                },
              }
            : director
        ),
      }));
      return;
    }

    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    let error: string | undefined;

    if (file.size > maxSizeInBytes) {
      error = "File size must be less than 5MB.";
    }

    setFormState((prevState) => ({
      ...prevState,
      directors: prevState.directors.map((director) =>
        director.id === id
          ? {
              ...director,
              idImage: error ? null : file,
              errors: {
                ...director.errors,
                idImage: error,
              },
            }
          : director
      ),
    }));
  };

  // Handle nationality selection with validation
  const handleSelect = (id: number, country: string) => {
    setFormState((prevState) => ({
      ...prevState,
      directors: prevState.directors.map((director) =>
        director.id === id ? { ...director, nationality: country } : director
      ),
    }));
  };

  // Add a new director
  const addDirector = () => {
    setFormState((prevState) => ({
      ...prevState,
      directors: [
        ...prevState.directors,
        {
          id: prevState.directors.length + 1,
          fullName: "",
          dateOfBirth: "",
          nationality: "",
          bvn: "",
          proofOfIdentity: "passport",
          idImage: null,
          errors: {},
        },
      ],
    }));
  };

  // Remove a director by ID
  const handleFileRemove = (id: number | string) => {
    setFormState((prevState) => ({
      ...prevState,
      directors: prevState.directors.filter((director) => director.id !== id),
    }));
  };

  // Function to validate all directors before submission
  const validateAllDirectors = (): boolean => {
    let isValid = true;

    const updatedDirectors = formState.directors.map((director) => {
      const errors = { ...director.errors };

      // Validate Full Name
      if (!director.fullName) {
        errors.fullName = "Full Name is required.";
        isValid = false;
      } else {
        delete errors.fullName;
      }
      // Validate Date of Birth
      if (!director.dateOfBirth) {
        errors.dateOfBirth = "Date of Birth is required.";
        isValid = false;
      } else {
        const age = calculateAge(director.dateOfBirth);
        if (age < 18) {
          errors.dateOfBirth = "Director must be at least 18 years old.";
          isValid = false;
        }
      }

      // Validate Nationality
      if (!director.nationality) {
        errors.nationality = "Nationality is required.";
        isValid = false;
      }

      // Validate BVN
      if (!director.bvn) {
        errors.bvn = "BVN is required.";
        isValid = false;
      } else if (director.bvn.length !== 11) {
        errors.bvn = "BVN must be exactly 11 digits.";
        isValid = false;
      } else if (!/^\d{11}$/.test(director.bvn)) {
        errors.bvn = "BVN must contain only numbers.";
        isValid = false;
      }

      // Validate Proof of Identity
      if (!director.proofOfIdentity) {
        errors.proofOfIdentity = "Proof of Identity is required.";
        isValid = false;
      }

      // Validate Document File
      if (!director.idImage) {
        errors.idImage = "Document file is required.";
        isValid = false;
      }

      return { ...director, errors };
    });

    setFormState((prevState) => ({
      ...prevState,
      directors: updatedDirectors,
    }));

    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateAllDirectors()) {
      // Update the form context with director data as shareholders
      const directorsarray = formState.directors; // Assuming this holds your array of directors

      const mappedShareholders = directorsarray.map((director) => ({
        fullName: director.fullName,
        dob: director.dateOfBirth, // mapping dateOfBirth to dob
        nationality: director.nationality,
        bvn: director.bvn,
        proofOfIdentity: director.proofOfIdentity,
        idImage: director.idImage,
      }));

      updateField("shareholders", mappedShareholders); 

      try {
        await sendRequest("POST", "/business-info", formData);
        toast.success("Form submitted successfully");
        navigate("/dashboard");
      } catch (error) {
        toast.error("An error occurred. Please try again.");
      }
    } else {
      toast.error("Validation failed.");
    }
  };

  return (
    <div className="lg:w-8/12 w-full py-14 lg:pl-20 px-10 bg-white dark:bg-atlas_black text-black dark:text-white font-manrope">
      <form className="pt-10 font-manrope" onSubmit={handleSubmit}>
        {formState.directors.map((director, index) => (
          <DirectorForm
            key={director.id}
            director={director}
            handleDirectorChange={handleDirectorChange}
            handleFileSelect={handleFileSelect}
            handleFileRemove={handleFileRemove}
            handleSelect={(country) => handleSelect(director.id, country)}
            index={index}
          />
        ))}
        <div className="flex justify-end">
          <button
            type="submit"
            className="py-2 px-9 rounded-md text-white font-semibold mt-4 bg-atlas_orange"
          >
            Save
          </button>
        </div>
      </form>

      <button
        type="button"
        onClick={addDirector}
        className="px-4 py-2 bg-transparent border-2 border-solid border-atlas_lightbrown/70 text-atlas_lightbrown/70 rounded-md mt-4 font-normal"
      >
        Add Director
      </button>
    </div>
  );
};

export default DirectorInfo;
