import React from "react";
import { IoCheckmarkCircle, IoRadioButtonOff } from "react-icons/io5";
import { MdRadioButtonChecked } from "react-icons/md";

interface StepButtonProps {
  completed: boolean;
  isActive: boolean; // New prop to indicate the active step
  onClick?: () => void;
  label: string;
  underText?: string;
}

const StepButton = ({
  completed,
  isActive,
  onClick,
  label,
  underText,
}: StepButtonProps) => (
    <div
    onClick={onClick}
    className="flex items-start space-x-2 rounded-md cursor-pointer pt-6"
  >
    <div className="flex-shrink-0">
      {completed ? (
        <IoCheckmarkCircle className="text-atlas_orange w-5 h-5 mt-1" />
      ) : isActive ? (
        <MdRadioButtonChecked className="text-atlas_orange w-5 h-5 mt-1" />
      ) : (
        <IoRadioButtonOff className="text-atlas_orange w-5 h-5 mt-1" />
      )}
    </div>

    <div className={`flex flex-col justify-center text-left  ${completed ? `text-white/70` : ' '}`}>
      <span>{label}</span>
      {underText && <span className="text-sm text-gray-500 mt-1 pr-20">{underText}</span>}
    </div>
  </div>
);

export default StepButton;
