interface Props {
  strokeColor?: string 
}
export default function ({strokeColor}:Props) {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 8.90446H17.5M5.83333 13.0711H6.66667M10 13.0711H10.8333M5 16.4045H15C16.3807 16.4045 17.5 15.2852 17.5 13.9045V7.23779C17.5 5.85708 16.3807 4.73779 15 4.73779H5C3.61929 4.73779 2.5 5.85708 2.5 7.23779V13.9045C2.5 15.2852 3.61929 16.4045 5 16.4045Z"
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
