import IdVerification from "./IdVerification";
import PersonalInfo from "./PersonalInfo";

interface props {
  onComplete: (step:string, data?: any) => void;
  idTypes?: any;
}

const StepOneContent = (props : props) => <PersonalInfo {...props}/>;
const StepTwoContent = (props: props) => <IdVerification {...props}/>;

const VerificationStages = [
  
  {
    id: "personal_info",
    label: "Personal Information",
    underText: "Submit information like your Name, Number & Address",
    component: StepOneContent,
  },
  {
    id: "id_verification",
    label: "Identity Verification",
    underText: "Submit a valid Identification card & complete the facial recognition test",
    component: StepTwoContent,
  },
];

export default VerificationStages;
