import React,{useState} from "react";
import CalenderIcon from "../Dashboard/CalenderIcon";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";

const NewLoan = () => {
    const [isChecked, setIsChecked] = useState(true);
  return (
    <div className="my-5 mx-10">
      <h3>Create New Loan</h3>

      <div className="text-atlas_orange my-9  flex justify-between">
        <div>
          <h5 className="text-left text-xs">Available Loan:</h5>
          <p className="text-white">
            1,000,000 <sub className="text-xs text-white/50">NGN</sub>
          </p>
        </div>
        <div>
          <h5 className="text-left text-xs">Eligible Loan:</h5>
          <p className="text-white">
            1,000,000 <sub className="text-xs text-white/50">NGN</sub>
          </p>
        </div>
      </div>
      <div className="mb-7">
        <h5 className="text-left text-xs pb-2">Input new Loan Amount:</h5>
        <input
          type="text"
          className="w-full border border-soild border-atlas_grey bg-transparent focus:outline-none p-2 rounded-lg text-white"
          placeholder="Enter Loan Amount"
        />
      </div>

      <p>Due Date</p>
      <div className="flex justify-between mb-10">
        <p>3rd May 2024</p>
        <CalenderIcon number={3} />
      </div>

      <div>
      <label className="inline-flex justify-center items-center cursor-pointer">
          <input
            type="checkbox"
            className="hidden"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            aria-hidden="true"
          />
          {isChecked ? (
            <MdCheckBox className="text-atlas_orange w-5 h-5 mt-1 mr-1" />
          ) : (
            <MdCheckBoxOutlineBlank className="w-5 h-5 mt-1 mr-1 rounded-sm " />
          )}
         <p className="text-xs">{' '}I agree to the {' '} 
            <span className="underline text-blue-500">
            term & condition
            </span> 
            {' '}of creating this loan</p>
        </label>
        
        <div className="flex justify-center py-4">
          <button className={`w-full  text-white p-3 rounded-lg ${isChecked ? 'bg-atlas_orange' : 'bg-atlas_orange/50 cursor-not-allowed'}`} disabled={!isChecked}>
            Create New Loan
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewLoan;
