export default function Copy() {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="0.5" y="0.5" width="20" height="20" fill="url(#pattern0_6359_25172)" fillOpacity="0.7" />
      <defs>
        <pattern id="pattern0_6359_25172" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_6359_25172" transform="scale(0.01)" />
        </pattern>
        <image
          id="image0_6359_25172"
          width="100"
          height="100"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAACXBIWXMAAAsTAAALEwEAmpwYAAADQUlEQVR4nO3dTYiNURzH8TOmJi9pZOEl2bC1UGNBiRFJ7GisLJQQkaUiJTYzC4tpFtIkRZGMrOQtDSuzIgtR1CRlFLFwWYyXr87MM6U799x757n3zvl3nt+nnu25//P8+j9vt85xTkREREQMANYAp4GrwAPgUSLHTaAP6AbanXXAOmCYYngL9DirgIPAOMUzCHQ4S4ADFNstoM0ZukwVsTPKHXMWAI+nlVZMX4HFFp6mqnkKXAB6Ezj6gKEaV4OjsQM5VaW4wy5BQBdQCsz5fuziroU6wyUMOBeY9/vYhT0MFNbnEgbsDMx7PHZhoZfAsy5hTL6pVxS7MAVSRoEY6xBgWx3HRmB109/w1SEN+5695W9SIK3rkLxuA52N1KUOab7XwHIFYqNDpowAc/MWpqes1jipQOx0iPcNWJCnMHVImTrP23xgC/CSsN0KZJbf1IElwOfAUAMzGWtqQHVImRzn8AqV3VEgEb5l+e9+gaGGZzqWOqQCBRKBOsQYdMmyBQViCwrEFhSILSgQW1AgtqBAbEGB2IICsQUFYgsKxBYUiC0oEFtQILagQGxBgdiCArEFBWILCsQWFIgtKBBbUCC2oEBsQYHYggKxBQViCwrEFhSILSgQW1AgtqBAbEGB2IICsQXDgYRWJD3vEgZsDcz7T+xA/FrolQy5hAHHA/P+FDsQv3RqJX4p1S6XIKATGA3M+1nsQDYTVspW79xV5ypr1o/tWWeEwvDOxA6kPdslQJi4KqyKGkg2YI/SmNCf8/w1N5Bs0EsU24tcyym1MJAO4DrF9BxY0cC5a34g2cBtfssG4AvFuWf0+7WvGjxvrQnkvx9Y5HcJAO4BHxLaW+QvMOYfbf3TVJ4beJRAGigspNslDAViiwIxxnIgPwKF7XEJAwYC874bu7B3gcIuu0Qx+brwJjDvwdjF3ajydHPCzLZ0TQIsrLJDkXcodoF7qc4vp/rEwParzThGqlyivV/AstiB+A+Vr2qEUhQXnQXZZ+7fFNtHYKmzIrtfFFUJWO+sAfYBPymWUWCtswpY6R95a9z8UjCW7T3f0EfKWQPMA3YAR7K/hHsTOPzHyf3ABmBO7HMsIiIiIiIiIiLiWuQf1Ea0GtipwC0AAAAASUVORK5CYII="
        />
      </defs>
    </svg>
  );
}
