import { useState } from "react";
import { IoChevronDown } from "react-icons/io5";
import { Combobox, ComboboxInput, ComboboxButton , ComboboxOptions , ComboboxOption } from "@headlessui/react";

function classNames(...classes: (string | boolean)[]) {
	return classes.filter(Boolean).join(" ");
}

export interface Item {
	name: string;
	id: string;
}

interface Props {
	items: Item[];
	onItemChange: (item: Item) => void;
	disabled?: boolean;
}

export default function DropDown({ items, onItemChange , disabled }: Props) {
	const [query, setQuery] = useState("");
	const [selectedItem, setSelectedItem] = useState(items[0]);

	const filteredItems =
		query === ""
			? items
			: items.filter((it) => {
					return it.name.toLowerCase().includes(query.toLowerCase());
				});

	return (
		<Combobox
			as="div"
			value={selectedItem}
			onChange={(it) => {
				if (it) {
					setSelectedItem(it);
					onItemChange(it);
				}
			}}
		>
			<div className="relative mt-2">
				<ComboboxInput
					className="w-full rounded-md border-0 bg-neutral-700 py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-atlas_orange sm:text-sm sm:leading-6"
					onChange={(event) => setQuery(event.target.value)}
					displayValue={(item: Item) => item?.name}
					disabled={disabled}
					
				/>
				<ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<IoChevronDown className="h-5 w-5 text-gray-400" aria-hidden="true" />
				</ComboboxButton>

				{filteredItems.length > 0 && (
					<ComboboxOptions className="absolute z-10 mt-1 max-h-40 w-full overflow-auto rounded-md bg-neutral-700 text-white py-1 text-base shadow-lg ring-1 ring-atlas_orange ring-opacity-5 focus:ring-atlas_orange focus:outline-none sm:text-sm">
						{filteredItems.map((item, i) => (
							<ComboboxOption
								key={item.id + i}
								value={item}
								className={({ active }) =>
									classNames(
										"relative cursor-default select-none text-white focus:ring-atlas_orange py-2 pl-3 pr-9",
										active ? "bg-atlas_orange" : ""
									)
								}
							>
								{({ active, selected }) => {
									return (
										<>
											<span className={classNames("block truncate", selected && "font-semibold")}>{item.name}</span>

											{selected && (
												<span
													className={classNames(
														"absolute inset-y-0 right-0 flex items-center pr-4",
														active ? "text-white" : "text-gray-900"
													)}
												>
													{/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
												</span>
											)}
										</>
									);
								}}
							</ComboboxOption>
						))}
					</ComboboxOptions>
				)}
			</div>
		</Combobox>
	);
}
