import React, { useState } from "react";
import PayoutForm from "./PayoutForm";
import InputPin from "./InputPin";
import TransActionStatus from "./TransActionStatus";
import Send from "../Modals/Send";
import { useAppContext } from "../../Context/AppContext";
import { TransferDetails } from "../../schema/transfer";
import { set } from "react-hook-form";

function Payout() {
	const { sendRequest } = useAppContext();
	const [stage, setStage] = useState(1);
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);
	const [transferDetails, setTransferDetails]: [
		TransferDetails,
		React.Dispatch<React.SetStateAction<TransferDetails>>,
	] = useState({} as TransferDetails);

	switch (stage) {
		case 1:
			return (
				<Send
					onClick={() => {
						setStage(2);
					}}
				/>
			);

		case 2:
			return (
				<PayoutForm
					onSuccess={() => {
						setStage(0);
					}}
					
				/>
			);

		// case 3:
		// 	return (
		// 		<InputPin
		// 			onClick={() => {
		// 				setStage(0);
		// 			}}
		// 		/>
		// 	);

		default:
			return <TransActionStatus />;
	}
}

export default Payout;
