import {useState} from 'react'
import Add from './Add'
import Transfer from './Transfer';
import Share from './Share';

interface AddmoneyProps {
  onclose: () => void;
}

const Addmoney = ({onclose}: AddmoneyProps) => {
    const [pageIndex, setPageIndex] = useState(0);

    // Advances to the next page
    const goToNextPage = () => {
      setPageIndex((prev) => prev + 1);
    };
  
    return (
      <div>
        {pageIndex === 0 && <Add onClick={goToNextPage} />}
        {pageIndex === 1 && <Transfer onNext={goToNextPage} />}
        {pageIndex === 2 && <Share onclose={onclose}/>}
      </div>
    );
}

export default Addmoney