import {useEffect, useState} from "react";
import emoji from "../../assets/icon _emoji smile upside down_.svg";
import CustomSelect from "./Customselect";
import logo from "../../assets/Logo.svg";
import { Link } from "react-router-dom";
import { useAppContext } from "../../Context/AppContext";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

interface Props {
  onComplete: (step: string, data?: any) => void;
}

interface SupportedCountries {
  [countryCode: string]: {
    name: string;
    id_types: Record<string, { label: string }>;
  };
}

interface Country {
  label: string;
  value: string;
}


const PersonalInfo = ({ onComplete }: Props) => {
  const userData = JSON.parse(localStorage.getItem("user") || "{}");
  const { sendRequest } = useAppContext();
  const [error, setError] = useState(""); 
  const [supportedCountries, setSupportedCountries] = useState<SupportedCountries | null>(null);
  const [selectedIdTypes, setSelectedIdTypes] = useState<{ id_types: Record<string, { label: string }>; country: string }>({ id_types: {}, country: "" });

  const calculateAge = (dob: Date) => {
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const nationalityResponse = await sendRequest("get", "/kyc/supported", '');
        setSupportedCountries(nationalityResponse.data.data.supported);
      } catch (error) {}
    };

    fetchData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  const handleResidenceCountrySelect = (selectedCountry: Country, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
      setFieldValue("residence", selectedCountry.value);
  };

  const validationSchema = Yup.object({
    dob: Yup.date()
      .required("Date of Birth is required")
      .test("age", "You must be 18 years or older.", (value) => {
        if (!value) return false;
        return calculateAge(value) >= 18;
      }),
    gender: Yup.string().required("Gender is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    postal_code: Yup.string().required("Postal Code is required"),
    residence: Yup.string().required("Country of Residence is required"),
    nationality: Yup.string().required("Nationality is required"),
  });

  const initialValues = {
    dob: "",
    gender: "male",
    address: "",
    city: "",
    postal_code: "",
    nationality: "",
    residence: "",
  };

  return (
    <div>
      <div className="flex lg:justify-end justify-between px-10 pt-6 bg-white dark:bg-atlas_black text-black dark:text-white font-manrope ">
        <Link to="/" className="flex items-center justify-between lg:hidden">
          <img className="w-12 h-12 p-2 cursor-pointer fill-atlas_orange" src={logo} alt="logo" />
        </Link>
        <p className="flex items-center text-white text-sm">
          We really need this part &nbsp; <img src={emoji} alt="emoji" />
        </p>
      </div>
      <div className="lg:w-4/6 w-full py-14 lg:pl-20 px-10">
        <h3 className="text-2xl font-manrope text-white font-semibold pt-8 pb-2">
          Personal Information
        </h3>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            const data = {
              dob: values.dob,
              gender: values.gender,
              address: values.address + ", " + values.residence,
              city: values.city,
              postal_code: values.postal_code,
            };

            try {
              // First request to submit personal info
              const response = await sendRequest("POST", "/personal-info", data);
              if (response.status === 200 && response.data.message === "success") {
                onComplete && onComplete("personal_info" , selectedIdTypes );
                localStorage.setItem("personal_infolocation", JSON.stringify(selectedIdTypes));
              }
            } catch (error) {
              setError("An error occurred. Please try again later.");
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({ setFieldValue, isSubmitting }) => (
            <Form className="pt-10 font-manrope text-black dark:text-white">
              <div className="grid lg:grid-cols-2 lg:gap-4 gap-8 mb-8">
                <div>
                  <label
                    htmlFor="first_name"
                    className="text-white font-normal text-sm mb-2 block"
                  >
                    Legal First name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="first_name"
                    name="first_name"
                    className="px-2 py-2 w-full text-white/50 bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                    value={userData.first_name}
                    disabled
                  />
                </div>

                <div>
                  <label
                    htmlFor="last_name"
                    className="text-white font-normal text-sm mb-2 block"
                  >
                    Legal Last name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="last_name"
                    name="last_name"
                    className="px-2 py-2 w-full text-white/50 bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                    value={userData.last_name}
                    disabled
                  />
                </div>
              </div>

              <div className="grid lg:grid-cols-2 lg:gap-4 gap-8 mb-8">
                <div>
                  <label
                    htmlFor="gender"
                    className="text-white font-normal text-sm mb-2 block"
                  >
                    Gender <span className="text-red-500">*</span>
                  </label>
                  <Field
                    as="select"
                    name="gender"
                    id="gender"
                    className="px-2 py-3 w-full bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                  >
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="others">Others</option>
                  </Field>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    className="text-red-500 text-sm "
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="dob"
                    className="text-white font-normal text-sm mb-2 block"
                  >
                    Date of Birth <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="date"
                    name="dob"
                    id="dob"
                    className="px-2 py-2 w-full bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                  />
                  <ErrorMessage
                    name="dob"
                    component="div"
                    className="text-red-500 text-sm "
                  />
                </div>
              </div>

               <div className="mb-8">
              <label
                htmlFor="address"
                className="text-white font-normal text-sm mb-2 block"
              >
                Residential Address <span className="text-red-500">*</span>
              </label>
              <Field
                type="text"
                name="address"
                id="address"
                className="px-2 py-2 w-full bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                placeholder="Residential Address"
              />
              <ErrorMessage
                name="address"
                component="div"
                className="text-red-500 text-sm "
              />
              </div>

              <div className="grid lg:grid-cols-2 lg:gap-4 gap-8 mb-8">
                <div>
                  <label
                    htmlFor="postal_code"
                    className="text-white font-normal text-sm mb-2 block"
                  >
                    Postal Code <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="postal_code"
                    id="postal_code"
                    className="px-2 py-2 w-full bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                    placeholder="Postal Code"
                  />
                  <ErrorMessage
                    name="postal_code"
                    component="div"
                    className="text-red-500 text-sm "
                  />
                </div>

                <div className="">
                  <label
                    htmlFor="city"
                    className="text-white font-normal text-sm mb-2 block"
                  >
                    City <span className="text-red-500">*</span>
                  </label>
                  <Field
                    type="text"
                    name="city"
                    id="city"
                    className="px-2 py-2 w-full bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border-atlas_slate dark:border-atlas_darkgray rounded-md"
                    placeholder="City"
                  />
                  <ErrorMessage
                    name="city"
                    component="div"
                    className="text-red-500 text-sm "
                  />
                </div>
              </div>

              <div className="relative mb-8">
                <label
                  htmlFor="residence"
                  className="text-white font-normal text-sm mb-2 block"
                >
                  Country of Residence <span className="text-red-500">*</span>
                </label>
                {supportedCountries && (
                  <CustomSelect
                    supportedCountries={supportedCountries}
                    onSelect={(selectedCountry: Country) => handleResidenceCountrySelect(selectedCountry, setFieldValue)}
                  />
                )}
                <ErrorMessage
                  name="residence"
                  component="div"
                  className="text-red-500 text-sm "
                />
              </div>

              <div className="relative mb-8">
                <label
                  htmlFor="nationality"
                  className="text-white font-normal text-sm mb-2 block"
                >
                  Nationality <span className="text-red-500">*</span>
                </label>
                {supportedCountries && (
                  <CustomSelect
                    supportedCountries={supportedCountries}
                    onSelect={(selectedCountry: Country) => {
                      const { value: countryCode, label: countryName } = selectedCountry;
                      setFieldValue("nationality", countryName); // Store/display full country name as needed
                      
                      if (supportedCountries) {
                        const countryInfo = supportedCountries[countryCode];
                        if (countryInfo) {
                          setSelectedIdTypes({ id_types: countryInfo.id_types, country:countryCode });
                        }
                      }
                    }}
                    text="Identity document will be needed for this nationality"
                  />
                )}
                <ErrorMessage
                  name="nationality"
                  component="div"
                  className="text-red-500 text-sm "
                />
              </div>

              {error && (
                <div className="text-red-500 text-sm my-4 ">{error}</div>
              )}

              <div className="flex justify-end py-5">
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="py-2 px-7 rounded-md text-black font-semibold mt-4 bg-atlas_orange"
                >
                  Continue
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PersonalInfo;


