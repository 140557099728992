import { Routes, Route, Navigate } from "react-router-dom";
import SignupPage from "./Pages/Auth/SignupPage_Individual";
import LoginPage from "./Pages/Auth/LoginPage";
import Dashboard from "./Pages/Dashboard/Dashboard";
import HomePage from "./Pages/Homepage2";
import PrivateRoute from "./Components/PrivateRoute";
import Kyc from "./Pages/Kyc";
import KYB from "./Pages/KYB";
import SignupPageBusiness from "./Pages/Auth/SignupPage_Business";
import ForgotPasswordPage from "./Pages/Auth/ForgotPasswordPage";
// import TwoFactorPage from "./Pages/Auth/TwoFactorPage";
// import ForgotPasswordPage from "./Pages/Auth/ForgotPasswordPage";

function App() {
	return (
		<Routes>
			<Route path="/" element={<HomePage />} />

			{/* <Route path="/about-us" element={<AboutUsPage />} /> */}
			<Route path="/signup" element={<Navigate to="/signup/individual" />} />
			<Route path="/signup/individual" element={<SignupPage />} />
			<Route path="/signup/business" element={<SignupPageBusiness />} />
			<Route path="/login" element={<LoginPage />} />
			<Route path="/reset-password" element={<ForgotPasswordPage />} />

			{/*       
      <Route path="/signup/2fa" element={<TwoFactorPage/>} />
      <Route path="/forgot-password" element={<ForgotPasswordPage/>}/> */}

			<Route element={<PrivateRoute />}>
				<Route path="/dashboard/*" element={<Dashboard />} />
				<Route path="/kyc" element={<Kyc />} />
				<Route path="/kyb" element={<KYB />} />
			</Route>
		</Routes>
	);
}

export default App;
