import { useState } from "react";
import CustomSelect from "./Customselect";
import { UploadButton } from "../button";
import { Director } from "../../util/Director";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

interface DirectorFormProps {
  director: Director;
  index: number;
  handleDirectorChange: (
    id: number,
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
  handleFileSelect: (id: number, file: File) => void;
  handleFileRemove: (id: number) => void;
  handleSelect: (value: string) => void;
}

const DirectorForm: React.FC<DirectorFormProps> = ({
  director,
  index,
  handleDirectorChange,
  handleFileSelect,
  handleFileRemove,
  handleSelect,
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  function shortenString(str: string, maxLength: number) {
    if (str.length <= maxLength) return str;

    const lastDotIndex = str.lastIndexOf(".");
    if (lastDotIndex === -1) {
      // No extension found, just truncate normally
      return str.slice(0, maxLength) + "...";
    }

    const extension = str.slice(lastDotIndex);
    const spaceForName = maxLength - extension.length - 3; // 3 chars for "..."

    if (spaceForName <= 0) {
      // Not enough room for name characters, just show "...extension"
      return "..." + extension;
    }

    return str.slice(0, spaceForName) + "..." + extension;
  }

  return (
    <div className="font-manrope">
      {index > 0 && <hr className="my-6 border-gray-300" />}
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-white text-lg">
          Director/Shareholder Information {index + 1}
        </h3>
        <div className="flex items-center gap-4">
          {index > 0 && (
            <>
              <button
                type="button"
                className="text-red-500"
                onClick={() => handleFileRemove(Number(director.id))}
              >
                Remove
              </button>
            </>
          )}
          <button
            type="button"
            className="text-gray-300 hover:text-white focus:outline-none"
            onClick={toggleCollapse}
            aria-label={
              isCollapsed ? "Expand Director Form" : "Collapse Director Form"
            }
          >
            {isCollapsed ? <FaChevronDown /> : <FaChevronUp />}
          </button>
        </div>
      </div>

      {isCollapsed && (
        <>
          <div className="mb-10">
            <label className="text-white text-sm mb-2 block">
              Fullname <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="fullName"
              className="px-2 py-2  w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange"
              value={director.fullName}
              onChange={(e) => handleDirectorChange(Number(director.id), e)}
              required
              placeholder="Fullname"
            />
          </div>

          <div className="grid lg:grid-cols-2 lg:gap-4">
            {/* Date of Birth */}
            <div className=" mb-10">
              <label className="text-white text-sm mb-2 block">
                Date of Birth <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                name="dateOfBirth"
                className={`px-2 py-2 w-full text-white/50 bg-atlas_silver dark:bg-atlas_darker border ${
                  director.errors.dateOfBirth
                    ? "border-red-500"
                    : "border-atlas_slate dark:border-atlas_darkgray"
                } rounded-md`}
                value={director.dateOfBirth}
                onChange={(e) => handleDirectorChange(Number(director.id), e)}
                required
              />
              {director.errors.dateOfBirth && (
                <p className="text-red-500 text-sm mt-1">
                  {director.errors.dateOfBirth}
                </p>
              )}
            </div>

            {/* Nationality */}
            <div className="mb-10">
              <label className="text-white text-sm mb-2 block">
                Nationality <span className="text-red-500">*</span>
              </label>
              <CustomSelect onSelect={handleSelect} />

              {director.errors.nationality && (
                <p className="text-red-500 text-sm mt-1">
                  {director.errors.nationality}
                </p>
              )}
            </div>
          </div>

          {/* BVN */}
          <div className="mb-10">
            <label className="text-white text-sm mb-2 block">
              BVN <span className="text-red-500">*</span>
            </label>
            <input
              type="number"
              name="bvn"
              className={`px-2 py-2 w-full text-white bg-atlas_silver dark:bg-atlas_darker border-opacity-20 border focus:ring-0 border-atlas_slate dark:border-atlas_darkgray rounded-md focus:outline-none focus:border-atlas_orange ${
                director.errors.bvn ? "border-red-500" : "border-atlas_orange"
              } rounded-md`}
              value={director.bvn}
              onChange={(e) => {
                const value = e.target.value;
                if (value.length <= 11) {
                  handleDirectorChange(director.id, e);
                }
              }}
              onInput={(e) => {
                if ((e.target as HTMLInputElement).value.length > 11) {
                  (e.target as HTMLInputElement).value = (
                    e.target as HTMLInputElement
                  ).value.slice(0, 11); // Truncate to 11 digits
                }
              }}
              required
              placeholder="Input BVN"
            />
            {director.errors.bvn && (
              <p className="text-red-500 text-sm mt-1">{director.errors.bvn}</p>
            )}
          </div>

          {/* Proof of Identity */}
          <div className="mb-10">
            <label className="text-white text-sm mb-2 block">
              Proof of Identity <span className="text-red-500">*</span>
            </label>
            <select
              name="proofOfIdentity"
              value={director.proofOfIdentity}
              onChange={(e) => handleDirectorChange(director.id, e)}
              className={`px-2 py-2  w-full text-white bg-atlas_silver dark:bg-atlas_darker border ${
                director.errors.proofOfIdentity
                  ? "border-red-500"
                  : "border-atlas_slate dark:border-atlas_darkgray"
              } rounded-md`}
              required
            >
              <option value="">Select Document Type</option>
              <option value="passport">Passport</option>
              <option value="nin">NIN</option>
              <option value="driversLicence">Driver's License</option>
            </select>
            {director.errors.proofOfIdentity && (
              <p className="text-red-500 text-sm mt-1">
                {director.errors.proofOfIdentity}
              </p>
            )}
          </div>

          {/* Upload Document */}
          <div className="">
            <div className="flex justify-between">
              <label className="text-white text-sm mb-2 block">
                Upload Document <span className="text-red-500">*</span>
              </label>
              {director.idImage ? (
                <div className="flex items-center mb-2">
                  <p className="text-atlas_orange text-sm">
                    {shortenString(director.idImage.name, 20)}
                  </p>
                  <button
                    type="button"
                    className="text-red-500 ml-2 text-xs"
                    onClick={() => handleFileRemove(director.id)}
                  >
                    X
                  </button>
                </div>
              ) : null}
            </div>

            {director.errors.idImage && (
              <p className="text-red-500 text-sm mt-1">
                {director.errors.idImage}
              </p>
            )}
            <UploadButton
              label="Upload Document"
              id={`idImage-${director.id}`}
              onFileSelect={(file) => {
                if (file) {
                  handleFileSelect(director.id, file);
                }
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DirectorForm;
