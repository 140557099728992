import { createContext, useState, ReactNode } from 'react';


// Initial form data structure
const initialFormData = {
  businessName: "",
  address: "",
  state: "",
  postalCode: "",
  website: "",
  supportEmail: "",
  boardResolution: "",
  cacDocument: "",
  companyProfile: "",
  memorandumOfAgreement: "",
  yearOfAllocation: "",
  shareholders: [
    {
      fullName: "",
      dob: "",
      nationality: "",
      bvn: "",
      proofOfIdentity: "",
      idImage: ""
    }
  ]
};

// Create the context with a default value (optional)
export const KybFormContext = createContext({
  formData: initialFormData,
  updateField: (field: any, value: any) => {},
  addShareholder: () => {},
  updateShareholder: (index: number, field: any, value: any) => {}
});


export const KybFormProvider = ({ children }: { children: ReactNode }) => {
  const [formData, setFormData] = useState(initialFormData);

  // Update a top-level field in the form
  const updateField = (field: any, value: any) => {
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  // Add a new empty shareholder
  const addShareholder = () => {
    setFormData(prev => ({
      ...prev,
      shareholders: [
        ...prev.shareholders,
        {
          fullName: "",
          dob: "",
          nationality: "",
          bvn: "",
          proofOfIdentity: "",
          idImage: ""
        }
      ]
    }));
  };

  // Update a specific shareholder's field by index
  const updateShareholder = (index: number, field: any, value: any) => {
    setFormData(prev => {
      const updatedShareholders = prev.shareholders.map((shareholder, idx) => {
        if (idx === index) {
          return { ...shareholder, [field]: value };
        }
        return shareholder;
      });
      return { ...prev, shareholders: updatedShareholders };
    });
  };

  return (
    <KybFormContext.Provider value={{ formData, updateField, addShareholder, updateShareholder }}>
      {children}
    </KybFormContext.Provider>
  );
};
