import React, { useEffect, useState } from "react";
import Button from "../button";
import { useAppContext } from "../../Context/AppContext";
import DropDown, { Item } from "../dropdown";
import Bank from "../../schema/bank";
import { useFormik } from "formik";
import TransferSchema, { TransferDetails } from "../../schema/transfer";
import { useDashboardContext } from "../../Context/DashboardContext";

interface Props {
  onSuccess: () => void;
  onClick?: () => void;
  TransferResponse?: React.Dispatch<React.SetStateAction<string>>;
}

function PayoutForm({ onClick, onSuccess, TransferResponse }: Props) {
  const { sendRequest } = useAppContext();
  const { fetchWallets } = useDashboardContext();
  const deafaultBankList = { BankCode: "", BankName: "Select Bank" };

  const [loading, setLoading] = useState(false);
  const [bankTransferLoader, setBankTransferLoader] = useState(false);
  const [accNo, setAccNo] = useState<string>("");
  const [accName, setAccName] = useState<string>();
  const [message, setMessage] = useState<string>();
  const [transfermessage, setTransferMessage] = useState<string>();
  const [transferDetails, setTransferDetails]: [
    TransferDetails,
    React.Dispatch<React.SetStateAction<TransferDetails>>,
  ] = useState({} as TransferDetails);
  const [banks, setBanks]: [Bank[], React.Dispatch<React.SetStateAction<Bank[]>>] = useState([] as Bank[]);

  const getBanks = async () => {
    try {
      const response = await sendRequest("GET", `/banks/NGN`, null);
      switch (response.status) {
        case 200:
          setBanks([deafaultBankList, ...response.data.data]);
          break;
        default:
          break;
      }
    } catch (error) {
      setBanks([deafaultBankList]);
      console.log({ error });
    }
  };

  const validateAccountNumber = async (bank_code: string) => {
    if (accNo.length < 10) {
      setMessage("Account number must be 10 digits");
      return;
    }
    setLoading(true);
    const values = {
      bank_code: bank_code,
      account_number: accNo,
    };
    try {
      const response = await sendRequest("POST", "/verify/account", values);
      // console.log(response);
      switch (response.status) {
        case 200:
          setAccName(response.data.data.name);
          setMessage("");
          break;
        default:
          return;
      }
    } catch (error: any) {
      setMessage("Invalid Account Details");
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, touched, errors } = useFormik({
    initialValues: {
      amount: transferDetails.amount,
      bank_code: transferDetails.bank_code,
      account_number: transferDetails.account_number,
      narration: transferDetails.narration,
      currency: transferDetails.currency,
    },
    // validationSchema: TransferSchema(100000, 10),
    onSubmit: async (values) => {
      setBankTransferLoader(true);
      values.currency = "NGN";
      values.account_number = accNo;
      values.narration = "test narration";
      let requestData = { ...values, amount: values.amount * 100 };

      try {
        const response = await sendRequest("POST", "/bank-transfer", requestData);
        switch (response.status) {
          case 200:
            onSuccess();
            fetchWallets();
            break;
          default:
            break;
        }
      } catch (error: any) {
        switch (error.code) {
          case "ERR_NETWORK":
            setTransferMessage(error.message);
            break;
          default:
            setTransferMessage(error.response.data.errors);
            break;
        }
      } finally {
        setBankTransferLoader(false);
      }
    },
  });

  useEffect(() => {
    setMessage("");

    const delayDebounce = setTimeout(() => {
      if (accNo.length > 0 && accNo.length < 10) {
        setMessage("Account number must be 10 digits");
      }
    }, 1000);

    return () => clearTimeout(delayDebounce);
  }, [accNo]);

  useEffect(() => {
    getBanks();
  }, []);

  return (
    <div className="font-sans">
      <div className="border-b border-atlas_orange">
        <button className={`flex-1 py-3 px-20 rounded-tl-lg bg-atlas_orange`}>Send Money To</button>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="border-b border-l border-r border-atlas_orange pt-10 px-[33px]">
          {/* <div className="items-end text-end text-atlas_orange text-sm">Send to beneficiary</div> */}
          <div>
            <div>
              <div className="flex flex-col items-start">
                <label className="font-bold text-sm">Account Number</label>
                <div className="flex gap-3 items-center w-full">
                  <div className="w-full flex-shrink-0">
                    <input
                      id="account_number"
                      name="account_number"
                      type="text"
                      inputMode="text"
                      placeholder="Account number"
                      onChange={(e) => setAccNo(e.target.value)}
                      defaultValue={transferDetails.account_number}
                      className="w-full p-2 my-1 rounded-md text-sm bg-neutral-700 border border-neutral-600 outline-none"
                    />
                    <div className="text-red-500 text-xs">{message}</div>
                  </div>
                </div>
              </div>

              <div
                className={`flex flex-col items-start mt-6 mb-[15px] pb-3 ${accName ? "border-b" : ""} border-atlas_darkgrey`}
              >
                <label className="font-bold text-sm">Bank</label>
                <div className="flex gap-3 items-center w-full">
                  <div className="w-full flex-shrink-0">
                    <DropDown
                      items={banks.map((bank) => ({
                        name: bank.BankName,
                        id: bank.BankCode,
                      }))}
                      onItemChange={(b) => {
                        handleChange({
                          target: {
                            value: b.id,
                            name: "bank_code",
                            id: "bank_code",
                          },
                        });
                        validateAccountNumber(b.id);
                      }}
                    />
                    <div className="text-start text-lg text-atlas_orange mt-5">
                      {loading ? "Fetching Account Details..." : accName}
                    </div>
                  </div>
                </div>
              </div>

              {accName && (
                <div>
                  <div className="">
                    <div className="flex flex-col items-start">
                      <label className="font-bold text-sm">Amount</label>
                      <div className="flex gap-3 items-center w-full">
                        <div className="w-full flex-shrink-0">
                          <input
                            type="number"
                            inputMode="tel"
                            id="amount"
                            name="amount"
                            onChange={handleChange}
                            placeholder="Amount"
                            className="w-full p-2 my-1 rounded-md text-sm bg-neutral-700 border border-neutral-600 outline-none"
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="mt-2 text-xs text-atlas_slategrey">
											Balance: <span className="font-semibold text-white"></span>
										</div> */}
                  </div>

                  <div className="mt-[30px]">
                    <Button
                      label={"Send Money"}
                      id=""
                      disable={bankTransferLoader}
                      className="mb-0"
                      loader={bankTransferLoader}
                    />
                    <div className="text-lg text-atlas_orange text-center my-5">{transfermessage}</div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default PayoutForm;
