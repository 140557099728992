import { useContext, useState, useEffect } from "react";
import myContext from "../../Context/AppContext";
import GroupIcon from "../../assets/Group 20";
import BriefcaseIcon from "../../assets/briefcase";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ChevronDown from "../../assets/ChevronDown";
import dashboardIcon from "../../assets/dashboardIcon";
import DevIcon from "../../assets/DevIcon";
import settingsicon from "../../assets/settingsicon";
import logouticon from "../../assets/logout";
// import ThemeToggle from "../ThemeToggle";

interface Props {
  children: JSX.Element;
}

const Sidebar = ({children}: Props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { sidebarOpen, updateSidebarOpen, topComponentRef } = useContext(myContext);

  const [activeNavItem, setActiveNavItem] = useState("/dashboard");
  const [sidebarHeight, setSidebarHeight] = useState("100vh");
  const [showDevs, setShowDevs] = useState(false);

  const MenuListTop = [
    { name: "dashboard", icon: dashboardIcon, route: "/dashboard" },
    { name: "wallet", icon: BriefcaseIcon, route: "/dashboard/wallet" },
    { name: "loans", icon: GroupIcon, route: "/dashboard/loans" },
  ];

  const MenuListBottom = [
    {
      name: "developers",
      icon: DevIcon,
      children: [
        { name: "webhooks", route: "/dashboard/developers/webhooks" },
        { name: "api keys", route: "/dashboard/developers/apikeys" },
      ],
    },
    { name: "settings", icon: settingsicon, route: "/dashboard/settings" },
    { name: "logout", icon: logouticon, route: "/logout" },
  ];

  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    // Prevent the default action of the event
    e.preventDefault();
    // Clear specific localStorage items
    localStorage.removeItem("Refresh-token");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("business");

    // Redirect to login page
    navigate("/login");
  };

  const handleItemClick = (itemName: any) => {
    setActiveNavItem(itemName);
    updateSidebarOpen(false);
  };


  useEffect(() => {
    const adjustSidebarHeight = () => {
      const topComponentHeight = topComponentRef.current?.offsetHeight || 0;
      setSidebarHeight(`calc(100vh - ${topComponentHeight}px)`);
    };

    // Set activeNavItem based on the current route
    const currentPath = location.pathname; // Assuming the structure is /dashboard/[name]
    if (currentPath !== activeNavItem) {
      setActiveNavItem(currentPath);
    }

    // Adjust sidebar height on mount and window resize
    adjustSidebarHeight();
    window.addEventListener("resize", adjustSidebarHeight);

    return () => window.removeEventListener("resize", adjustSidebarHeight);
  }, [location.pathname, topComponentRef]);

  return (
    <div className="">
      {/* Overlay */}
      {sidebarOpen && (
        <div className="fixed bg-black bg-opacity-50 z-10 lg:hidden" onClick={() => updateSidebarOpen(false)}></div>
      )}

      {/* Sidebar */}
      <div
        className={`fixed border-r border-atlas_gray left-0 transform ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
          } w-10/12 md:w-3/12 bg-white dark:bg-atlas_dark text-white transition-transform duration-300 ease-in-out z-20 lg:translate-x-0 lg:inset-auto lg:w-60`}
      >
        {/* Sidebar content */}

        <nav className="flex flex-col justify-between pb-10 lg:pb-5" style={{ height: sidebarHeight }}>
          {/* Your nav items here */}
          <ul className="capitalize border-b border-atlas_gray ">
            {MenuListTop.map((item, index) => (
              <Link key={index} to={item.route}>
                <li key={index}
                  className={`flex items-center my-5 md:my-2 ml-5 pl-4 py-2 cursor-pointer rounded-l-xl dark:hover:bg-gray-300 hover:bg-atlas_dark hover:text-gray-500 text-atlas_black ${activeNavItem === item.route
                      ? "dark:bg-white bg-atlas_dark text-black rounded-l-xl transition-transform duration-700 ease-in-out"
                      : "bg-transparent text-white"
                    }`}
                  onClick={() => handleItemClick(item.route)}
                >
                  <div className="w-8 h-8 rounded-full flex items-center justify-center mr-2">
                    <item.icon
                      className={`w-5 h-5`}
                      strokeColor={activeNavItem === item.route ? "#000000" : "#FFFFFF"}
                      fillColor={activeNavItem === item.route ? "#000000" : "none"}
                    />{" "}
                  </div>
                  {item.name}
                </li>
              </Link>
            ))}
          </ul>

          <ul className="capitalize">
            {MenuListBottom.map((tab, index) => {
              if (tab.route) {
                return (
                  <div key={index}>
                    <NavLink
                      to={tab.route !== "/logout" ? tab.route : "#"}
                      key={tab.name}
                      onClick={(e) => {
                        if (tab.route === "/logout") {
                          handleLogout(e); // Call logout logic if the route is "/logout"
                        } else {
                          handleItemClick(tab.route); // Handle normal navigation
                        }
                      }}
                      className={`flex items-center gap-3 ml-5 pl-4 my-5 md:my-2 py-2 cursor-pointer rounded-l-xl hover:bg-gray-300 hover:text-gray-100 hover:bg-opacity-10
                        ${activeNavItem === tab.route
                          ? "bg-white text-black rounded-l-xl transition-transform duration-700 ease-in-out"
                          : "bg-transparent text-white"
                        }
                      `}
                    >
                      <tab.icon strokeColor={activeNavItem === tab.route ? "#000000" : "#FFFFFF"} />
                      {tab.name}
                    </NavLink>
                  </div>
                );
              } else {
                return (
                  <div key={index}>
                    <div
                      className={`flex items-center my-5 md:my-2 ml-5 pl-4 py-2 cursor-pointer rounded-l-xl hover:bg-gray-300 hover:text-gray-100 hover:bg-opacity-10
                        ${activeNavItem === tab.route
                          ? "bg-white text-black rounded-l-xl transition-transform duration-700 ease-in-out"
                          : "bg-transparent text-white"
                        }
                      `}
                    >
                      <div className={`flex w-full items-center gap-3`} onClick={() => setShowDevs(!showDevs)}>
                        <tab.icon strokeColor={activeNavItem === tab.route ? "#000000" : "#FFFFFF"} />
                        {tab.name}
                        <ChevronDown />
                      </div>
                    </div>
                    {showDevs && (
                      <div className="block flex-col">
                        {tab.children?.map((elem, i) => (
                          <NavLink
                            key={i}
                            to={elem.route}
                            onClick={() => handleItemClick(elem.route)}
                            className={`flex items-center gap-3 ml-5 pl-12 my-5 md:my-2 py-2 cursor-pointer rounded-l-xl hover:bg-gray-300 hover:text-gray-100 hover:bg-opacity-10
                              ${activeNavItem === elem.route
                                ? "bg-white text-black rounded-l-xl transition-transform duration-700 ease-in-out"
                                : "bg-transparent text-white"
                              }
                            `}
                          >
                            <div className="">{elem.name}</div>
                          </NavLink>
                        ))}
                      </div>
                    )}
                  </div>
                );
              }
            })}
            {/*<div className="flex flex-col items-center ml-5">
							<ThemeToggle />
						</div>*/}
          </ul>
        </nav>
      </div>

      <div className="lg:ml-60">
      {children}
      </div>
    </div>
  );
};

export default Sidebar;
