import { FaChevronDown } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import UsersTable from "../UsersTable";
import { useState } from "react";
import Modal from "../Modals/Modal";


function Users() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contentType, setContentType] = useState("");

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const handleTitle = (title: string) => {
    setContentType(title);
  };

  return (
    <aside className="py-5 px-10 font-manrope">
      <div className="flex items-center justify-between pb-5 border-b border-zinc-700">
        <h1 className="text-3xl text-gray-100">Users</h1>
        <div className="flex items-center justify-between space-x-5">
          <a className="cursor-pointer text-atlas_orange hover:brightness-90">View roles</a>
          <div>
            <div className="grid grid-cols-1 hover:brightness-95">
              <select
                id="roles"
                name="roles"
                defaultValue="All roles"
                className="col-start-1 row-start-1 w-full cursor-pointer appearance-none border-2 border-neutral-500 rounded-md py-1.5 pl-3 pr-8 text-gray-100 text-base bg-neutral-800 outline-none sm:text-sm/6"
              >
                <option>All roles</option>
                <option>Few roles</option>
                <option>Some roles</option>
              </select>
              <FaChevronDown
                aria-hidden="true"
                className="pointer-events-none col-start-1 row-start-1 mr-2 size-4 self-center justify-self-end text-gray-100 sm:size-4"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-5">
        <UsersTable />
        <button
          onClick={() => {
            handleTitle("NewUser");
            handleOpenModal();
          }}
          className="inline-flex my-5 space-x-2 font-semibold items-center rounded-md px-3 py-1 bg-atlas_orange hover:brightness-90">
          <FiPlusCircle className="size-7 pr-2" /> Add User
        </button>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        type={contentType}
      />
    </aside>
  )
}

export default Users;