import React, { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { ICurrency } from "../schema/currency";
import { ILedger } from "../schema/ledger";
import { useAppContext } from "./AppContext";

// Define the shape of the context
const DashboardContext = createContext(
  {} as {
    user: any;
    business: any;
    loading: boolean;
    isDashboardVerificationComplete: boolean;
    setDashboardVerificationComplete: (value: boolean) => void;
    login: (userData: any) => void;
    logout: () => void;
    wallets: ILedger[];
    fetchWallets: () => void;
    currencies: ICurrency[];
  }
);

export const useDashboardContext = () => useContext(DashboardContext);

interface Props {
  children: ReactNode;
}

export const DashboardProvider = ({ children }: Props) => {
  const { sendRequest } = useAppContext();
  const [user, setUser] = useState<any | null>(null); // For individual users
  const [business, setBusiness] = useState<any | null>(null); // For business accounts
  const [loading, setLoading] = useState(true); // Loading state
  const [isDashboardVerificationComplete, setIsDashboardVerificationComplete] = useState(false); // KYC/KYB complete state
  const [currencies, setCurrencies] = useState<ICurrency[]>([]);
  const [wallets, setWallets] = useState<ILedger[]>([]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const fetchUserData = async () => {
        try {
          // Fetch user and business data from localStorage
          const userData: any = JSON.parse(localStorage.getItem("user") || "{}");
          const businessData: any = JSON.parse(localStorage.getItem("business") || "{}");

          setUser(userData);
          setBusiness(businessData);

          // Check if verification is complete for the logged-in user type
          if (
            userData?.kyc_status === "verified" || // Individual user verified
            businessData?.kyc_verified === true // Business verified
          ) {
            setIsDashboardVerificationComplete(true);
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
          localStorage.removeItem("token");
        } finally {
          setLoading(false);
        }
      };

      fetchUserData();
      fetchCurrencies();
      fetchWallets();
    } else {
      setLoading(false);
    }
  }, []);

  function fetchWallets() {
    sendRequest("GET", "/ledgers", null)
      .then((response: any) => {
        setWallets(response.data.data);
        // setWallets([...response.data.data, {
        //   currency_code: "TEST",
        //   balance: 1200000000,
        //   id: "1111",
        //   status: "active",
        //   description:"description",
        //   bank_accounts: [
        //     {
        //       bank_name: "Atlas Bank",
        //       address: "something",
        //       account_type:"savings",
        //       bank_code: "000012",
        //       account_number: "1234567890",
        //     }
        //   ],
        // }]);
      })
      .catch((error: any) => {
        if (error.response.data.errors === "you have no wallet") {
          setWallets([]);
        }
      });
  }

  function fetchCurrencies() {
    sendRequest("GET", "/currencies", null)
      .then((res: any) => {
        // console.log(res);
        setCurrencies(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const login = (userData: any) => {
    localStorage.setItem("token", userData.access_token);
    localStorage.setItem("Refresh-token", userData.refresh_token);

    if (userData?.business) {
      localStorage.setItem("business", JSON.stringify(userData.business));
      setBusiness(userData.business);
    } else {
      localStorage.setItem("user", JSON.stringify(userData));
      setUser(userData);
    }

    // Update verification status
    if (
      userData?.kyc_status === "verified" || // Individual user verified
      userData?.business?.kyc_verified === true // Business verified
    ) {
      setIsDashboardVerificationComplete(true);
    }
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("business");
    setUser(null);
    setBusiness(null);
    setIsDashboardVerificationComplete(false); // Reset verification state
  };

  const contextValue = {
    user,
    business,
    loading,
    isDashboardVerificationComplete,
    setDashboardVerificationComplete: setIsDashboardVerificationComplete,
    login,
    logout,
    wallets,
    currencies,
    fetchWallets,
  };

  return <DashboardContext.Provider value={contextValue}>{children}</DashboardContext.Provider>;
};

export default DashboardContext;
