import { FaChevronDown } from "react-icons/fa6";
import { useDashboardContext } from "../Context/DashboardContext";

function AllWallets() {
  const { currencies } = useDashboardContext();
  return (
    <div>
      <div className="grid grid-cols-1 hover:brightness-95">
        <select
          id="wallets"
          name="wallets"
          defaultValue="All wallets"
          className="col-start-1 row-start-1 w-full cursor-pointer appearance-none border-2 border-neutral-500 rounded-md py-1 pl-3 pr-8 text-gray-100 text-base bg-neutral-800 outline-none sm:text-sm/6"
        >
          <option>All wallets</option>
          {currencies.map((currency, i) => {
            return <option key={i}>{currency.Code} Wallet</option>
          })}
        </select>
        <FaChevronDown
          aria-hidden="true"
          className="pointer-events-none col-start-1 row-start-1 mr-2 size-4 self-center justify-self-end text-gray-100 sm:size-4"
        />
      </div>
    </div>
  )
}

export default AllWallets;
