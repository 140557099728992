import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const sections = [
  {
    page: '1',
  },
  {
    page: '2',
  },
  {
    page: '3',
  },
  {
    page: '4',
  },
]

function TableNav() {
  return (
    <section className="flex items-center justify-end mt-5">
      <div className="flex items-center space-x-1 justify-between font-manrope text-gray-200">
        <button title="Previous" className="size-8 p-1 border-2 border-neutral-600 text-gray-900 rounded-md bg-neutral-500 brightness-90 hover:brightness-75 focus:brightness-110">
          <IoIosArrowBack className="size-5"/>
        </button>
        {sections.map((section, index) => (
          <button key={index} className="size-8 p-1 text-sm border-2 border-atlas_darkslate rounded-md bg-neutral-900 brightness-90 hover:brightness-75 focus:brightness-110">
            {section.page}
          </button>
        ))}
        <button title="More" className="size-8 p-1 text-sm border-2 border-atlas_darkslate rounded-md bg-neutral-900 brightness-90 hover:brightness-75 focus:brightness-110">
          ...
        </button>
        <button className="size-8 p-1 text-sm border-2 border-atlas_darkslate rounded-md bg-neutral-900 brightness-90 hover:brightness-75 focus:brightness-110">
          12
        </button>
        <button title="Next" className="size-8 p-1 border-2 border-atlas_darkslate text-gray-100 rounded-md bg-neutral-900 brightness-90 hover:brightness-75 focus:brightness-110">
          <IoIosArrowForward className="size-5"/>
        </button>
      </div>
    </section>
  )
}

export default TableNav;
