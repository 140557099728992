interface Props {
	strokeColor?: string;
}

export default function ({ strokeColor }: Props) {
	return (
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">

<path d="M9.16667 13.9048L5.83333 10.5715M5.83333 10.5715L9.16667 7.23812M5.83333 10.5715L17.5 10.5715M13.3333 13.9048V14.7381C13.3333 16.1188 12.214 17.2381 10.8333 17.2381H5C3.61929 17.2381 2.5 16.1188 2.5 14.7381V6.40479C2.5 5.02407 3.61929 3.90479 5 3.90479H10.8333C12.214 3.90479 13.3333 5.02407 13.3333 6.40479V7.23812" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
</svg>

	);
}

