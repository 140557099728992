import React from "react";
import { FaChevronDown } from "react-icons/fa";


function NewUser() {
  return (
    <div className="font-manrope">
      <div className="border-b border-atlas_orange">
        <button className={`flex-1 py-3 px-14 w-1/2 text-nowrap rounded-tl-lg font-semibold bg-atlas_orange`}>
          Create new user
        </button>
      </div>

      <div className="flex flex-col space-y-5 border-b border-l border-r border-atlas_orange py-5 px-10">
        <div className="flex flex-col items-start">
          <label>Name</label>
          <input
            type="text"
            placeholder="Adebowale"
            className="w-full p-2 rounded-md bg-neutral-700 border border-neutral-600 outline-none"
          />
        </div>
        <div className="flex flex-col items-start">
          <label>Email</label>
          <input
            type="email"
            placeholder="AdebowJack@gmail.com"
            className="w-full p-2 rounded-md bg-neutral-700 border border-neutral-600 outline-none"
          />
        </div>
        <div className="flex flex-col items-start">
          <label>Phone number</label>
          <div className="flex items-center space-x-2 w-full px-2 rounded-md bg-neutral-700 border border-neutral-600">
            <span>+234</span>
            <input
              type="number"
              placeholder="Placeholder"
              className="p-2 rounded-md bg-neutral-700 outline-none"
            />
          </div>
        </div>
        <div>
          <label>Select Role</label>
          <div className="grid grid-cols-1 hover:brightness-95">
            <select
              id="roles"
              name="role"
              defaultValue="Staff"
              className="col-start-1 row-start-1 w-full cursor-pointer appearance-none border-2 border-neutral-600 rounded-md py-2 pl-3 text-gray-100 text-base bg-neutral-700 outline-none sm:text-sm/6"
            >
              <option>Staff</option>
              <option>Super Admin</option>
              <option>Administrator</option>
              <option>Developer</option>
              <option>Finance Officer</option>
              <option>Customer Support</option>
            </select>
            <FaChevronDown
              aria-hidden="true"
              className="pointer-events-none col-start-1 row-start-1 mr-2 size-4 self-center justify-self-end text-gray-100 sm:size-4"
            />
          </div>
        </div>
        <div className="flex items-center justify-center py-5">
          <button className="btn">Create User</button>
        </div>
      </div>
    </div>
  )
}

export default NewUser;