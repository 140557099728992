import FileUpload from "./FileUpload";
import emoji from "../../assets/icon _Happy and Smile_.svg";
import logo from "../../assets/Logo.svg";
import { Link } from "react-router-dom";
import {useState, useEffect} from "react";

interface Props {
  onComplete: (step:string) => void;
  idTypes?: any;
}

const IdVerification = ({ onComplete , idTypes }: Props) => {

  const [idType, setIdType] = useState<any | null>(idTypes);
 
  const nextStep = () => {
    onComplete && onComplete('id_verification');
  };

  useEffect(() => {
    if (!idTypes) {
      const storedValue = localStorage.getItem("personal_infolocation");
      if (storedValue) {
        try {
          // Assuming storedValue is a JSON string representing your idTypes
          const parsedValue = JSON.parse(storedValue);
          setIdType(parsedValue);
        } catch (error) {
          console.error("Error parsing stored idTypes:", error);
        }
      }
    }
  }, [idTypes]); 
  
  return (
    <div className="">
      <div className="flex lg:justify-end justify-between items-center px-10 pt-6 bg-white dark:bg-atlas_black text-black dark:text-white font-manrope ">
      <Link to="/" className="flex items-center justify-between lg:hidden">
        <img className="w-12 h-12 p-2 cursor-pointer fill-atlas_orange" src={logo} alt="logo" />
      </Link>
      <p>This is the last step... I promise</p><img src={emoji} alt="emoji" />
      </div>

      <div className="lg:pt-10 lg:px-20 px-10">
        <h3 className="text-2xl font-manrope text-white font-semibold pt-8 pb-2">
          Identity Verification
        </h3>

        <p className="py-4 font-manrope text-white">
          Provide a Goverment Issued ID card
        </p>

        <div className="py-7 flex lg:flex-row flex-col font-manrope text-white gap-11">
          <div>
            <p className="font-semibold">Image requirements</p>

            <div className="bg-atlas_gray py-5 px-7 rounded-xl mt-8">
              <ul className="text-gray-300 text-sm list-disc">
                <li>File size should not be over 3mb</li>
                <li>Clear & not blurry</li>
                <li>JPG, JPEG, PNG</li>
              </ul>
            </div>
          </div>

          <div className="bg-atlas_gray lg:w-6/12 w-full rounded-xl">
            <FileUpload onNext={nextStep} idType = {idType} />
            {/* {renderModalContent()} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default IdVerification;
