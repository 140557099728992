import React from "react";
import CalenderIcon from "../Dashboard/CalenderIcon";

const Repay = () => {
  return (
    <div className="my-5 mx-10 font-sans">
      <h3>Repay Loan</h3>

      <div className="text-atlas_orange my-9  flex justify-between">
        <div>
          <h5 className="text-left text-xs">Available Loan:</h5>
          <p className="text-white">
            1,000,000 <sub className="text-xs text-white/50">NGN</sub>
          </p>
        </div>
        <div>
          <h5 className="text-left text-xs">Eligible Loan:</h5>
          <p className="text-white">
            1,000,000 <sub className="text-xs text-white/50">NGN</sub>
          </p>
        </div>
      </div>
      <div className="mb-7">
        <h5 className="text-left text-xs pb-2">Select Loan</h5>
        <select className="py-3 px-3 bg-atlas_gray focus:outline-none border border-solid border-white/50 text-white rounded-lg">
          <option>Loan 1</option>
          <option>Loan 2</option>
          <option>Loan 3</option>
        </select>
      </div>

      <p>Due Date</p>
      <div className="flex justify-between mb-10">
        <p>4rd April 2024</p>
        <CalenderIcon number={4} />
      </div>

      <div>
        <select
          id="payment"
          className="px-2 py-3 rounded-lg border border-solid my-3 border-white/50 focus:outline-none flex-shrink-0 z-10 inline-flex items-center text-sm font-medium text-left bg-atlas_gray text-white "
        >
          <option selected>Select Payment Method</option>
          <option value="usd">PayPal</option>
          <option value="gbp">Bank Transfer</option>
          <option value="ngn">Debit/Credit Card</option>
        </select>

        <div className="flex justify-center py-4">
          <button
            className={`w-full bg-atlas_orange text-white p-3 rounded-lg`}
          >
            Repay Loan
          </button>
        </div>
      </div>
    </div>
  );
};

export default Repay;
