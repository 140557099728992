import CheckIcon from "../../assets/CheckIcon";
import ConfettiIcon from "../../assets/ConfettiIcon";

interface Props {
	TransferMessage?: string
}

function TransactionSuccessful({ TransferMessage }: Props) {
	return (
		<div className="grid justify-center pb-10">
			<div className="my-10 grid justify-center">
				<div>
					<ConfettiIcon />
				</div>
				<div className="items-center flex justify-center">
					<CheckIcon />
				</div>

				<div className=" text-center font-semibold text-[24px]">Transaction Successful</div>
				<div className="text-atlas_darkslate text-sm text-center">
					Your Transaction has been processed successfully!
				</div>
			</div>
		</div>
	);
}

export default TransactionSuccessful;
