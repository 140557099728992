import { useState, useEffect } from "react";
import LoginImage from "../../assets/LoginImage.png";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAppContext } from "../../Context/AppContext";
import Spinner from "../../Components/spinner";
import { FiAlertCircle } from "react-icons/fi";
import Logo from "../../Components/Logo";
import { toast } from "react-toastify";
import OTPPage from "./OTPPage";

function ErrorMessage({ error }: { error: string }) {
  return <div className="flex justify-start py-2 text-red-500">{error}</div>;
}

const SignInSchema = Yup.object().shape({
  email: Yup.string().email().required("Email is required"),
  password: Yup.string().required("Password is required"),
});

interface LoginResponseData {
  resend_duration?: number;
  access_token: string;
  refresh_token: string;
  user: any;
  business?: any;
}

interface LoginResponse {
  status: number;
  data: {
    message: string;
    data: LoginResponseData;
  };
}

const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { sendRequest } = useAppContext();
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [stage, setStage] = useState<"login" | "otp">("login");
  const [email, setEmail] = useState<string>("");
  const [resendDuration, setResendDuration] = useState(0);

  useEffect(() => {
    // Clear specific localStorage items when the component loads
    localStorage.removeItem("Refresh-token");
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("business");
  }, []);

  function loginUser(loginData: LoginResponseData) {
    // store the token/refresh_token/user in the localstorage
    localStorage.setItem("token", loginData.access_token);
    localStorage.setItem("Refresh-token", loginData.refresh_token);

    //This isnt the best way to store user data, but for now it will do
    localStorage.setItem("user", JSON.stringify(loginData.user));

    if (loginData.business) {
      localStorage.setItem("business", JSON.stringify(loginData.business));
    }

    toast.success("Login Successful");
    window.location.href = "/dashboard";
  }

  const { handleSubmit, handleChange, errors } = useFormik({
    enableReinitialize: true,
    initialValues: { email: "", password: "" },
    validationSchema: SignInSchema,
    onSubmit: async (values: any) => {
      setLoading(true);
      setEmail(values.email);
      // use sendRequest from the context
      sendRequest("POST", "/login", values)
        .then((response: LoginResponse) => {
          console.log({ response });

          if (response.status === 202) {
            setResendDuration(response.data.data.resend_duration ?? 90);
            setStage("otp");
            return;
          }
          loginUser(response.data.data);
        })
        .catch((err: any) => {
          console.log(err);

          let errMsg = "An error occurred, please try again";
          if (typeof err.response?.data?.errors === "string") {
            errMsg = err.response.data.errors;
          } else if (err.response?.data?.message) {
            errMsg = err.response.data.message;
          } else if (err.response?.data.errors) {
            errMsg = err.response.data.errors.join(", ");
          }

          toast.error(errMsg);
          setError(errMsg);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  function submitOTP(otp: string) {
    if (otp.length < 6) {
      toast.error("must be 6 digits code");
      return Promise.reject("invalid 6 digit code");
    }
    return sendRequest("POST", "/login/verify-otp", {
      code: otp,
      email: email,
    })
      .then((res: LoginResponse) => {
        console.log(res);
        loginUser(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
        if (err.response && err.response.data && err.response.data.errors) {
          toast.error(err.response.data.errors);
          // setError(err.response.data.errors);
        }
      });
  }

  function resendCode() {
    toast.error("not available at the moment");
    return;
    // sendRequest("POST", "/login/resend-otp", {
    //   email: email,
    // }).then((res: any) => {
    //   console.log(res);
    // }).catch((err: any) => {
    //   console.log(err);
    // });
  }

  return stage === "otp" ? (
    <OTPPage
      title="Enter the 6 digit code sent to your email address"
      onSubmit={submitOTP}
      resendCode={resendCode}
      resendDuration={resendDuration}
    />
  ) : (
    <>
      {/* {loading && <SplashScreen />} */}
      {/* <div style={{ display: loading ? "none" : "block" }}> */}
      <div>
        <div className="lg:flex ">
          <div className="bg-white dark:bg-atlas_black flex flex-col flex-1 min-h-screen lg:h-auto gap-y-10">
            <div className="lg:mx-16 md:mx-16 mx-12 pt-10 items-center flex justify-between">
              <Logo />
            </div>

            <div className="flex flex-1 flex-col ">
              <div className="font-sans lg:px-16 md:px-16 px-12 pb-5">
                <h3 className="text-4xl pb-2 text-atlas_black dark:text-white">Sign In</h3>
                <p className="font-normal text-opacity-70 text-sm text-slate-600 dark:text-atlas_darkslate">
                  Enter the following information correctly to login your Atlax account
                </p>
              </div>

              <form className="lg:px-16 md:px-16 px-12 font-manrope" onSubmit={handleSubmit}>
                <div className="text-sm">{error ? <ErrorMessage error={error} /> : ""}</div>
                <div className="mb-4">
                  <label htmlFor="email" className="text-atlas_black dark:text-white font-normal text-base block">
                    Email
                  </label>
                  <div className="relative flex items-center">
                    <input
                      type="email"
                      name="email"
                      className={`${errors.email ? "border-red-600" : "border-none"} px-3 py-3 w-full text-black dark:text-white rounded-md bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                      onChange={handleChange}
                      required
                      placeholder="someone@email.com"
                      inputMode="email"
                    />
                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                      {errors.email ? <FiAlertCircle className="text-red-600" /> : ""}
                    </div>
                  </div>
                  <div className="text-sm">{errors.email ? <ErrorMessage error={errors.email.toString()} /> : ""}</div>
                </div>

                <label htmlFor="password" className="text-atlas_black dark:text-white font-normal text-base block">
                  Password
                </label>
                <div className="relative flex items-center">
                  <input
                    type={show ? "text" : "password"}
                    name="password"
                    className={`${errors.email ? "border-red-600" : "border-none"} px-3 py-3 w-full text-black dark:text-white rounded-md bg-atlas_silver dark:bg-atlas_gray focus:outline-none border border-atlas_darkslate focus:border focus:border-atlas_orange`}
                    onChange={handleChange}
                    required
                    placeholder="Password"
                  />
                  <div className="flex space-x-5 items-center">
                    <div className="absolute inset-y-0 right-0 pr-9 flex items-center cursor-pointer">
                      {errors.email ? <FiAlertCircle className="text-red-600" /> : ""}
                    </div>
                    <div
                      className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
                      onClick={() => setShow(!show)}
                    >
                      {show ? (
                        <AiOutlineEyeInvisible className="text-black dark:text-white" color="" />
                      ) : (
                        <AiOutlineEye className="text-black dark:text-white" color="" />
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex justify-between items-end">
                  <div className="text-sm">
                    {errors.password ? <ErrorMessage error={errors.password.toString()} /> : ""}
                  </div>
                  <div className="flex justify-end py-2">
                    <Link
                      to="/reset-password"
                      className="cursor-pointer italic text-xs text-atlas_orange text-opacity-90 hover:brightness-90"
                    >
                      forgot password?
                    </Link>
                  </div>
                </div>

                <button type="submit" disabled={loading} className={`btn py-3 mt-5 mb-3`}>
                  {loading ? <Spinner /> : "Sign in"}
                </button>
                <div className="flex space-x-2 items-center place-content-center italic text-sm">
                  <p className="text-atlas_gray dark:text-atlas_grey opacity-85">Don't have an Atlax account?</p>
                  <Link
                    to="/signup"
                    className="cursor-pointer font-normal text-atlas_orange transition-all ease-in duration-150 hover:brightness-90 hover:underline"
                  >
                    Sign Up
                  </Link>
                </div>
              </form>
            </div>
          </div>

          <div className="hidden lg:block bg-atlas_dark flex-1">
            <div className="flex justify-center items-center h-full">
              <img className="h-screen w-full object-cover" alt="side" src={LoginImage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
