export default function () {
	return (
		<svg width="411"  viewBox="0 0 411 172" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_6123_40008)">
				<path d="M77.7949 73.7583L74.0879 68.8008L65.7928 73.495L69.4998 78.4525L77.7949 73.7583Z" fill="#00B8A9" />
				<path d="M76.3781 29.3049L79.543 29.998L78.9186 26.3297L75.7537 25.6366L76.3781 29.3049Z" fill="#00B8A9" />
				<path d="M95.4424 28.6519L100.604 29.1992L98.0731 17.9825L92.912 17.4352L95.4424 28.6519Z" fill="#FFDE7D" />
				<path d="M107.07 80.3839L106.709 75.9688L99.2642 72.6431L99.6254 77.0582L107.07 80.3839Z" fill="#F8F3D4" />
				<path d="M35.9496 26.3707L39.166 25.0312L38.3215 20.3636L35.105 21.703L35.9496 26.3707Z" fill="#F8F3D4" />
				<path d="M53.0785 33.7755L58.1348 33.957L57.6781 24.7609L52.6218 24.5794L53.0785 33.7755Z" fill="#FFDE7D" />
				<path d="M136.29 93.2339L137.854 88.4199L133.166 86.072L131.603 90.886L136.29 93.2339Z" fill="#FFDE7D" />
				<path d="M53.4173 87.7598L53.1094 84.5254L46.9768 84.6383L47.2847 87.8727L53.4173 87.7598Z" fill="#00B8A9" />
				<path d="M123.246 146.93L121.869 142.123L112.483 143.176L113.86 147.983L123.246 146.93Z" fill="#F8F3D4" />
				<path d="M20.1429 42.5317L22.0449 40.1602L15.8534 34.8217L13.9513 37.1933L20.1429 42.5317Z" fill="#F8F3D4" />
				<path d="M120.537 128.145L123.172 126.629L120.799 123.223L118.164 124.739L120.537 128.145Z" fill="#FFDE7D" />
				<path d="M214.04 15.1503L211.27 14.1094L210.82 16.6858L213.59 17.7267L214.04 15.1503Z" fill="#00B8A9" />
				<path d="M126.438 68.8806L130.734 67.2734L124.296 57L120 58.6072L126.438 68.8806Z" fill="#00B8A9" />
				<path d="M40.8104 106.198L45.9199 106.65L45.2878 97.5104L40.1783 97.0584L40.8104 106.198Z" fill="#F6416C" />
				<path d="M28.9582 41.1876L26.3633 37.6172L21.6907 41.2382L24.2856 44.8087L28.9582 41.1876Z" fill="#F8F3D4" />
				<path d="M70.7274 151.754L73.6738 151.188L71.4103 142.35L68.4638 142.917L70.7274 151.754Z" fill="#00B8A9" />
				<path d="M204.324 47.33L202.018 42.5L195 44.7648L197.306 49.5949L204.324 47.33Z" fill="#FFDE7D" />
				<path d="M214.778 19.5207L210.918 16.0059L206.07 20.8811L209.931 24.3959L214.778 19.5207Z" fill="#FFDE7D" />
				<path d="M151.447 28.5615L148.17 24.2734L142.76 28.2782L146.037 32.5663L151.447 28.5615Z" fill="#FFDE7D" />
				<path d="M237.503 30.2056L236.971 26.9141L233.924 26.7416L234.457 30.0331L237.503 30.2056Z" fill="#F8F3D4" />
				<path d="M172.977 49.5561L176.465 48.9395L175.031 40.78L171.544 41.3966L172.977 49.5561Z" fill="#F6416C" />
				<path d="M185.696 84.3691L185.623 80.0898L180.001 77.9992L180.074 82.2784L185.696 84.3691Z" fill="#F8F3D4" />
				<path d="M164.109 57.3849L166.879 54.4883L162.98 50.5503L160.211 53.4469L164.109 57.3849Z" fill="#FFDE7D" />
				<path d="M337.967 87.5142L340.932 86.0664L337.33 80.2854L334.365 81.7332L337.967 87.5142Z" fill="#FFDE7D" />
				<path d="M342.945 64.9996L339.014 65.0684L339.553 70.1927L343.484 70.1239L342.945 64.9996Z" fill="#00B8A9" />
				<path d="M326.703 73.7828L331.123 73.5254L328.421 68.2043L324.001 68.4617L326.703 73.7828Z" fill="#00B8A9" />
				<path d="M343.106 139.071L340.721 135.789L335 136.769L337.385 140.051L343.106 139.071Z" fill="#F8F3D4" />
				<path d="M375.051 121.215L375.875 117.275L371.993 117L371.169 120.94L375.051 121.215Z" fill="#F6416C" />
				<path d="M350.658 126.711L354.07 126.217L352.888 122.703L349.476 123.197L350.658 126.711Z" fill="#00B8A9" />
				<path d="M312.979 21.5694L308.795 17.7344L300.047 27.5623L304.231 31.3973L312.979 21.5694Z" fill="#F6416C" />
				<path d="M271.289 113.297L274.205 111.762L267.263 102.641L264.347 104.176L271.289 113.297Z" fill="#00B8A9" />
				<path d="M228.753 104.128L227.133 101.695L222 105.388L223.62 107.821L228.753 104.128Z" fill="#F6416C" />
				<path d="M386.685 31.7898L386.539 29.0352L379.023 28.6433L379.169 31.398L386.685 31.7898Z" fill="#FFDE7D" />
				<path d="M384.593 34.0263L387.143 32.6934L381.679 24.8327L379.13 26.1656L384.593 34.0263Z" fill="#FFDE7D" />
				<path d="M361.136 57.7116L363.42 56.5781L357.196 48.7923L354.912 49.9258L361.136 57.7116Z" fill="#F6416C" />
				<path d="M257.816 71.9664L257.744 68.3945L253.355 66.2484L253.427 69.8202L257.816 71.9664Z" fill="#00B8A9" />
				<path d="M292.06 42.2256L294.428 40.3145L289.8 34.608L287.433 36.5192L292.06 42.2256Z" fill="#F8F3D4" />
				<path d="M234.951 97.647L236.514 94.2617L227.963 91.3285L226.4 94.7138L234.951 97.647Z" fill="#F8F3D4" />
				<path d="M315.764 107.273H318.744L315.45 98.9394H312.469L315.764 107.273Z" fill="#F6416C" />
				<path d="M346.12 42.997L345.188 39.3711L341.13 40.073L342.062 43.6989L346.12 42.997Z" fill="#F8F3D4" />
				<path d="M283.968 44.9195L285.244 42.3555L277.138 37.2325L275.862 39.7966L283.968 44.9195Z" fill="#00B8A9" />
			</g>
			<defs>
				<clipPath id="clip0_6123_40008">
					<rect width="411" height="172" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}
