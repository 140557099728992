import Copy from "../copier";

interface Props {
  Apikey: string;
  Secretkey: string;
}

function ApiKeysForm({ Apikey, Secretkey }: Props) {
  return (
    <div className="w-full">
      <div className="flex flex-col">
        <section className="flex flex-col my-10 space-y-5 w-1/2">
          <div className="flex flex-col items-start">
            <label>Api Key</label>
            <div className="flex gap-3 items-center w-full">
              <ApiKeyValue text={Apikey}/>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <label>Secret Key</label>
            <div className="flex gap-3 items-center w-full">
              <ApiKeyValue text={Secretkey}/>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

interface prop {
  text: string;
}
function ApiKeyValue({ text }: prop) {
  return <>
    <div className="w-full flex-shrink-0">
      <div className="p-2 rounded-md bg-neutral-700 border border-neutral-600 overflow-clip">
        {text.replaceAll(text.slice(-32), "*".repeat(32))}
      </div>
    </div>
    <Copy text={text} />
  </>;
}
export default ApiKeysForm;
