import WebhookForm from "./WebhookForm";
import { IoIosReturnLeft } from "react-icons/io";

function Webhooks() {
  const TestMode = process.env.REACT_APP_ENV

  return (
    <>
      <section className="font-manrope p-10">
        <div className="flex justify-between items-center">
          <nav className="flex flex-wrap items-center justify-between">
            <div className="block w-full flex-grow lg:flex lg:w-auto lg:items-center">
              <div className="mt-4 mr-4 px-5 lg:mt-0 border-b-2 border-atlas_dark_orange text-atlas_dark_orange">{TestMode === "prod" ? "Live Webhooks" : "Test Webhooks"}
              </div>
            </div>
          </nav>
          <div>
            <span className="text-atlas_orange flex justify-between space-x-2 items-center hover:brightness-90">
              <label className="text-sm cursor-pointer">Webhook logs</label>
              <IoIosReturnLeft className="size-6 rotate-180 cursor-pointer" />
            </span>
          </div>
        </div>
        <div className="container mx-auto px-4">
          <WebhookForm />
        </div>
      </section>
    </>
  );
}

export default Webhooks;
