import { useState } from "react";
import VerificationStages from "./StepsArray";

interface Props {
  activeStep: string;
  onComplete: (step: string) => void;
}

const MainContent = ({ activeStep, onComplete }: Props) => {
  const ActiveComponent = VerificationStages.find(
    (step: { id: string; }) => step.id === activeStep
  )?.component;

  return (
    <div className="main-content">
      {ActiveComponent ? (
        <ActiveComponent onComplete={onComplete} />
      ) : (
        <div>Select a step</div>
      )}
    </div>
  );
};

export default MainContent;
