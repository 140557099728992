export default function () {
	return (
		<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M8.33268 17.2381L11.666 3.90479M14.9993 7.23812L18.3327 10.5715L14.9993 13.9048M4.99935 13.9048L1.66602 10.5715L4.99935 7.23812"
				stroke="white"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}
