import React, { useState, useRef, useEffect } from "react";
import PinComponent from "../PinComponent";

interface PinProps {
  length?: number; // Optional: Number of inputs, default is 6
  onChange?: (pin: string) => void; // Optional: Callback when all inputs are filled
  className?: string; // Optional: Additional CSS classes for styling
}

function Pin({ length = 4, onChange, className = "" }: PinProps) {
  const inputRefs = useRef<React.RefObject<HTMLInputElement>[]>(
    Array.from({ length }, () => React.createRef<HTMLInputElement>())
  );
  const [pins, setPins] = useState<string[]>(Array(length).fill(""));

  useEffect(() => {
    // Focus the first input on component mount
    inputRefs.current[0]?.current?.focus();
  }, [length]);

  useEffect(() => {
    // Check if all inputs are filled
    const fullCode = pins.join("");
    if (onChange) { onChange(fullCode); }
  }, [pins, length, onChange]);

  const handleChange = (value: string, index: number) => {
    const newPins = [...pins];
    newPins[index] = value.slice(0, 1);
    setPins(newPins);

    if (value) {
      focusNextInput(index);
    }
  };

  const focusNextInput = (currentIndex: number) => {
    if (currentIndex < length - 1) {
      inputRefs.current[currentIndex + 1]?.current?.focus();
    }
  };

  const focusPrevInput = (currentIndex: number) => {
    if (currentIndex > 0) {
      inputRefs.current[currentIndex - 1]?.current?.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace" && !pins[index] && index > 0) {
      focusPrevInput(index);
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pasteData = e.clipboardData.getData("text").slice(0, length);
    const pasteDataArray = pasteData.split("");
    setPins(
      pasteDataArray.concat(Array(length - pasteDataArray.length).fill(""))
    );

    const nextIndexToFocus =
      pasteDataArray.length < length ? pasteDataArray.length : length - 1;
    inputRefs.current[nextIndexToFocus]?.current?.focus();
  };

  return (
    <section className="px-16 py-6 font-manrope">
      <div className="flex pb-5 border-b border-neutral-700 items-center text-white">
        <h2 className="text-2xl">Pin</h2>
      </div>
      <main className="flex flex-col space-y-10 py-5">
        <div className="flex flex-col">
          <label className="text-lg font-normal">Current PIN</label>
          <PinComponent />
        </div>
        <div>
          <label className="text-lg font-normal">New PIN</label>
          <PinComponent />
        </div>
        <div>
          <label className="text-lg font-normal">Confirm new PIN</label>
          <PinComponent />
        </div>
        <div className="flex items-center justify-end w-fit">
          <button className="btn self-end">Confirm new password</button>
        </div>
      </main>
    </section>
  )
}

export default Pin;