interface Props {
	fillColor?: string;
	strokeColor?: string;
}
export default function ({ fillColor, strokeColor }: Props) {
	return (
		<svg width="20" height="20" viewBox="0 0 20 21" fill={fillColor} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.66602 8.90453L8.95818 3.07079C9.56685 2.58383 10.4318 2.58383 11.0405 3.07079L18.3327 8.90453"
				stroke={strokeColor ? strokeColor : "white"}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M7.89868 3.94583L4.56534 6.65416C3.96013 7.14589 3.65753 7.39176 3.49575 7.73163C3.33398 8.07151 3.33398 8.46141 3.33398 9.24122V12.238C3.33398 15.3806 3.33398 16.9521 4.31029 17.9283C5.28661 18.9046 6.85795 18.9046 10.0007 18.9046C13.1433 18.9046 14.7147 18.9046 15.691 17.9283C16.6673 16.9521 16.6673 15.3806 16.6673 12.238V9.24122C16.6673 8.46141 16.6673 8.07151 16.5056 7.73163C16.3437 7.39176 16.0412 7.14589 15.436 6.65416L12.1027 3.94583C11.0958 3.12777 10.5924 2.71875 10.0007 2.71875C9.4089 2.71875 8.90548 3.12777 7.89868 3.94583Z"
				stroke={strokeColor ? strokeColor : "white"}
			/>
			<path d="M8.125 14.1128H11.875" stroke={"white"} strokeLinecap="round" />
		</svg>
	);
}
