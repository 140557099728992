import React, { useState } from "react";
import { MdClose } from "react-icons/md";
import Buy from "./Buy";
import Sell from "./Sell";
import Convert from "./Convert";
import Trade from "./Trade";
import NewLoan from "./NewLoan";
import Repay from "./Repay";
import NewUser from "./NewUser";
import CreateWallet from "./CreateWallet";
import Payout from "../Payout/Payout";
import Addmoney from "./Addmoney";
// import Send from "./Send";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	type: string;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, type }) => {
	const [contentType, setContentType] = useState("Buy");

	if (!isOpen) return null;

	const renderContent = () => {
		switch (type) {
			case "Buy":
				switch (contentType) {
					case "Buy":
						return <Buy />;
					case "Sell":
						return <Sell />;
					default:
						return <Buy />;
				}
			case "Convert":
				return <Convert />; // Assuming you have a Convert component
			case "Trade":
				return <Trade />; // Assuming you have a Trade component
			case "NewLoan":
				return <NewLoan />;
			case "Send":
				return <Payout />;
			case "Repay":
				return <Repay />;
			case "NewUser":
				return <NewUser />;
			case "Addmoney":
				return <Addmoney onclose={onClose}/>;	
			case "CreateWallet":
				return <CreateWallet />;
			default:
				return null;
		}
	};

	return (
		<div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center text-white">
			<div className="bg-atlas_gray rounded-lg shadow-lg max-w-md w-full md:w-2/4 relative">
				<div className="absolute -top-10 -right-0">
					<button onClick={onClose} className="text-white bg-atlas_lightgrey p-2 rounded-full hover:bg-atlas_orange">
						<MdClose className="text-black size-5" />
					</button>
				</div>
				{(type === "Buy" || type === "Sell") && (
					<div className="flex justify-center items-center border-b border-atlas_orange">
						<button
							className={`flex-1 py-2 ${contentType === "Buy" ? "bg-atlas_orange text-black" : "text-white"} rounded-tl-lg`}
							onClick={() => setContentType("Buy")}
						>
							Buy
						</button>
						<button
							className={`flex-1 py-2 ${contentType === "Sell" ? "bg-atlas_orange text-black" : "text-white"} rounded-tr-lg`}
							onClick={() => setContentType("Sell")}
						>
							Sell
						</button>
					</div>
				)}
				<div className="">{renderContent()}</div>
			</div>
		</div>
	);
};

export default Modal;
