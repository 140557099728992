import React from 'react'
import { useDashboardContext } from '../../Context/DashboardContext';
import SettingsMenu from './SettingsMenu';
import { Routes, Route } from 'react-router-dom';
import AccountsPage from './AccountsPage';
import DashboardPage from './DashboardPage';
import LoansPage from './LoansPage';
import ProfilePage from './ProfilePage';
import TradePage from './TradePage';
import WalletPage from './WalletPage';
import Users from './Users';
import General from './General';
import PasswordPage from './Password';
import Pin from './Pin';


const SettingsPage = () => {

  return (
    <section className='font-manrope h-full text-white pl-10 pt-10'>
      <div className='pb-5'>
        <h2 className='text-2xl'>Settings</h2>
      </div>
      <aside className='flex flex-grow items-start justify-between border-t border-zinc-700'>
        <SettingsMenu />
        <div className='lg:ml-60 font-manrope flex-grow '>
          <Routes>
            <Route path="/general" element={<General />} />
            <Route path="/password" element={<PasswordPage />} />
            <Route path="/pin" element={<Pin />} />
            <Route path="/users" element={<Users />} />
          </Routes>
        </div>
      </aside>

    </section>
  )
}

export default SettingsPage