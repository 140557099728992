import React, { useEffect, useState } from "react";
import DropDown from "../dropdown";
import { useDashboardContext } from "../../Context/DashboardContext";
import { ILedger } from "../../schema/ledger";
import Copy from "../copier";

interface TransferProps {
  onNext: () => void;
}

const Transfer: React.FC<TransferProps> = ({ onNext }) => {
  const { wallets } = useDashboardContext();
  const [selectedWallet, setSelectedWallet] = useState<ILedger>();

  useEffect(() => {
    if (wallets.length) setSelectedWallet(wallets[0]);
  }, [wallets]);

  return (
    <div className="font-manrope">
      <div className="border-b border-atlas_orange">
        <button className={`flex-1 py-3 px-10 rounded-tl-lg bg-atlas_orange`}>Bank Transfer </button>
      </div>

      <div className="px-10 pt-5">
        <p>Wallet</p>
        {/* Controlled Dropdown Select */}
        <DropDown
          items={wallets?.map(w => ({id: w.id, name: w.currency_code}))}
          onItemChange={(item) => {
            const w = wallets.find(w => w.id === item.id);
            if (w) setSelectedWallet(w);
          }}
        />
      </div>

      {/* List of items with copy icons */}
      <div className="flex items-center justify-center flex-col py-6">
        {selectedWallet?.bank_accounts?.map((bank_account, index) => (
          <div key={index} className="">
            <div className="mr-2 font-semibold">Bank Name: {bank_account.bank_name}</div>
            <div className="flex mr-2 font-semibold space-x-2">
              <span>Bank Account Number: {bank_account.account_number}</span>
              <Copy text={bank_account.account_number}/>
            </div>
          </div>
        ))}
      </div>

      <div className="flex justify-center my-4">
        <button className="w-4/5 bg-atlas_orange text-white p-3 rounded-lg" onClick={onNext}>
          Share
        </button>
      </div>
    </div>
  );
};

export default Transfer;
