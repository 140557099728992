import BusinessDoc from "./BusinessDoc";
import BusinessInfo from "./BusinessInfo";
import DirectorInfo from "./DirectorInfo";


interface props {
  onComplete: (step:string) => void;
}

const StepOneContent = (props : props) => <BusinessInfo {...props}/>;
const StepTwoContent = (props: props) => <BusinessDoc {...props}/>;
const StepThreeContent = (props: props) => <DirectorInfo {...props}/>;

const VerificationStages = [
  
  {
    id: "business_info",
    label: "Business Information",
    underText: "Submit information like your Business Name, Registration Number & Address",
    component: StepOneContent,
  },
  {
    id: "business_doc",
    label: "Business Document",
    underText: "Submit your business documents",
    component: StepTwoContent,
  },
  {
    id: "director_info",
    label: "Director/Shareholders Information",
    underText: "Submit business director/shareholder’s full name, Date of Birth, Nationality and Proof of Identity",
    component: StepThreeContent,
  },
];

export default VerificationStages;
